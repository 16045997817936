/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import {
  useGetCaseInfosQuery,
  useGetCaseRiskQuery,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Collapse,
} from '@mui/material'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import ContractInfosContainer from './ContractComponents/ContractInfosContainer'

/* Type imports ------------------------------------------------------------- */
import type { MesureDeclareeReleveeVerifiee } from 'API/__generated__/Api'
import { TypePersonne } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface BigContainerProps {
  addMargin?: boolean;
}

const BigContainer = styled.div<BigContainerProps>`
  display: flex;
  width: 100%;
  gap: 6%;
  margin-top: ${(props) => props.addMargin ? '10px' : '0px'};

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    gap: 10px;
  }
`

interface LittleContainerProps {
  addBorder?: boolean;
}

const LittleContainer = styled.div<LittleContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  box-shadow: ${(props) => props.addBorder ? `-2px 0 0 -1px ${props.theme.palette.secondary.main} inset` : 'none'};
  box-sizing: border-box;

  @media ${(props) => props.theme.media.mobile.portrait} {
    box-shadow: none;
    margin-bottom: ${(props) => props.addBorder ? '10px' : '0px'};
  }
`

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
  justify-content: space-between;

  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 160px 1fr;
    gap: 5px;
  }
`

const CardContainer = styled(Card)`
  margin-top: 20px;
`

const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 5px 0px 10px;
`

const BlueTitle = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
`

const BoldSeparator = styled.div`
  width: calc(100% + 40px);
  margin-left: -20px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  margin-top: 10px;
`

const MiniBoldSeparator = styled(BoldSeparator)`
  width: 100%;
  margin: 10px 0px;
`

const PlafondLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface BoldVerfiedValueProps {
  isValid: boolean;
}

const BoldVerfiedValue = styled.b<BoldVerfiedValueProps>`
  color: ${(props) => props.isValid === false ? 'red' : 'initial'};
`

/* Component declaration ---------------------------------------------------- */
interface ContractPageProps {}

const ContractPage: React.FC<ContractPageProps> = () => {
  const { caseId } = useParams<{ caseId: string }>()
  const [ expanded, setExpanded ] = useState<boolean>(false)

  const {
    currentData: risk,
    isFetching: isFetchingRisk,
  } = useGetCaseRiskQuery(caseId || '')
  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId || '')

  const insuredPerson = useMemo(() => caseInfos?.personnes?.find((person) => person.type === TypePersonne.Assure), [ caseInfos ])

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  const verifyValue = (value: MesureDeclareeReleveeVerifiee | undefined): boolean => {
    if (value === undefined) {
      return true
    }
    if (value.valeurDeclaree === value.valeurRelevee) {
      return true
    }
    return false
  }

  return (
    <div>
      <LargeTitle>
        Contrat
      </LargeTitle>
      <ContractInfosContainer
        title="Contrat"
        isFetching={isFetchingRisk || isFetchingCaseInfos}
      >
        <BigContainer>
          <LittleContainer>
            <InfoContainer>
              N° contrat / Police
              <b>
                {insuredPerson?.police}
              </b>
            </InfoContainer>
            <InfoContainer>
              Ref condition générale
              <b>
                {risk?.contratRex?.referenceDisposition}
              </b>
            </InfoContainer>
            <InfoContainer>
              Date souscription
              <b>
                {DateUtils.APIStrToLocalDateString(risk?.contratRex?.dateSouscription)}
              </b>
            </InfoContainer>
            <InfoContainer>
              Date dernière modification
              <b>
                {DateUtils.APIStrToLocalDateString(risk?.contratRex?.dateEffet)}
              </b>
            </InfoContainer>
            <InfoContainer>
              Montant de la franchise
              <b>
                {risk?.contratRex?.franchise}
              </b>
            </InfoContainer>
          </LittleContainer>
          <LittleContainer>
            <InfoContainer>
              Type de contrat souscrit
              <b>
                {risk?.contratRex?.nom}
              </b>
            </InfoContainer>
            <InfoContainer>
              Ref annexes
              <b>
                {risk?.contratRex?.referenceAnnexe}
              </b>
            </InfoContainer>
            <InfoContainer>
              Indice souscription
              <b>
                {risk?.contratRex?.indiceSouscription}
              </b>
            </InfoContainer>
            <InfoContainer>
              Indice réglement
              <b>
                {risk?.contratRex?.indiceReglement}
              </b>
            </InfoContainer>
          </LittleContainer>
        </BigContainer>
        <CardContainer>
          <CardTitleContainer onClick={handleExpandClick}>
            <BlueTitle>
              Plafonds
            </BlueTitle>
            <DropdownArrow expanded={expanded.toString()} />
          </CardTitleContainer>
          <BoldSeparator />
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
          >
            <CardContent>
              {
                risk?.contratRex?.plafondsDetailDeBien?.map((plafond, index) => (
                  <div key={`${plafond.code}-${index}`}>
                    <PlafondLine>
                      {plafond.libelle}
                      <b>
                        {`${plafond.value} €`}
                      </b>
                    </PlafondLine>
                    {
                      index !== (risk?.contratRex?.plafondsDetailDeBien?.length || 0) - 1 &&
                        <MiniBoldSeparator />
                    }
                  </div>
                ))
              }
            </CardContent>
          </Collapse>
        </CardContainer>
      </ContractInfosContainer>
      <br />
      <ContractInfosContainer
        title="Risques"
        isFetching={isFetchingRisk}
      >
        <LittleContainer>
          <InfoContainer>
            Conformité du risque
            <b>
              {risk?.conformite?.verification}
            </b>
          </InfoContainer>
          <InfoContainer>
            Commentaire
            <b>
              {risk?.conformite?.commentaire}
            </b>
          </InfoContainer>
        </LittleContainer>
        <BigContainer addMargin>
          <LittleContainer>
            <InfoContainer>
              Qualité de l'assuré
              <b>
                {risk?.qualiteAssure?.qualite?.libelle}
              </b>
            </InfoContainer>
            <InfoContainer>
              Adresse du risque
              <b>
                {getAddress(risk?.adresseRisque?.adresse)}
              </b>
            </InfoContainer>
          </LittleContainer>
          <LittleContainer>
            <InfoContainer>
              Type de risque
              <b>
                {risk?.type?.type?.libelle}
              </b>
            </InfoContainer>
            <InfoContainer>
              Usage de risque
              <b>
                {risk?.usage?.usage?.libelle}
              </b>
            </InfoContainer>
          </LittleContainer>
        </BigContainer>
        <br />
        <BigContainer>
          <LittleContainer addBorder>
            <BlueTitle>
              Risque déclaré
            </BlueTitle>
            <InfoContainer>
              Nombre de pièces
              <b>
                {risk?.verification?.nombreDePiece?.valeurDeclaree}
              </b>
            </InfoContainer>
            <InfoContainer>
              Surface
              <b>
                {risk?.verification?.surface?.valeurDeclaree}
              </b>
            </InfoContainer>
            <InfoContainer>
              Nombre de dépendances
              <b>
                {risk?.verification?.nombreDeDependance?.valeurDeclaree}
              </b>
            </InfoContainer>
            <InfoContainer>
              Surface Annexe
              <b>
                {risk?.verification?.surfaceAnnexe?.valeurDeclaree}
              </b>
            </InfoContainer>
            <InfoContainer>
              Nombre d'étages
              <b>
                {risk?.verification?.nombreDEtage?.valeurDeclaree}
              </b>
            </InfoContainer>
            <InfoContainer>
              Nombre de salarié
              <b>
                {risk?.verification?.nombreDeSalarie?.valeurDeclaree}
              </b>
            </InfoContainer>
            <InfoContainer>
              Chiffre d'affaire
              <b>
                {risk?.verification?.chiffreDAffaire?.valeurDeclaree}
              </b>
            </InfoContainer>
          </LittleContainer>
          <LittleContainer>
            <BlueTitle>
              Risque relevé
            </BlueTitle>
            <InfoContainer>
              Nombre de pièces
              <BoldVerfiedValue isValid={verifyValue(risk?.verification?.nombreDePiece)}>
                {risk?.verification?.nombreDePiece?.valeurRelevee}
              </BoldVerfiedValue>
            </InfoContainer>
            <InfoContainer>
              Surface
              <BoldVerfiedValue isValid={verifyValue(risk?.verification?.surface)}>
                {risk?.verification?.surface?.valeurRelevee}
              </BoldVerfiedValue>
            </InfoContainer>
            <InfoContainer>
              Nombre de dépendances
              <BoldVerfiedValue isValid={verifyValue(risk?.verification?.nombreDeDependance)}>
                {risk?.verification?.nombreDeDependance?.valeurRelevee}
              </BoldVerfiedValue>
            </InfoContainer>
            <InfoContainer>
              Surface Annexe
              <BoldVerfiedValue isValid={verifyValue(risk?.verification?.surfaceAnnexe)}>
                {risk?.verification?.surfaceAnnexe?.valeurRelevee}
              </BoldVerfiedValue>
            </InfoContainer>
            <InfoContainer>
              Nombre d'étages
              <BoldVerfiedValue isValid={verifyValue(risk?.verification?.nombreDEtage)}>
                {risk?.verification?.nombreDEtage?.valeurRelevee}
              </BoldVerfiedValue>
            </InfoContainer>
            <InfoContainer>
              Nombre de salarié
              <BoldVerfiedValue isValid={verifyValue(risk?.verification?.nombreDeSalarie)}>
                {risk?.verification?.nombreDeSalarie?.valeurRelevee}
              </BoldVerfiedValue>
            </InfoContainer>
            <InfoContainer>
              Chiffre d'affaire
              <BoldVerfiedValue isValid={verifyValue(risk?.verification?.chiffreDAffaire)}>
                {risk?.verification?.chiffreDAffaire?.valeurRelevee}
              </BoldVerfiedValue>
            </InfoContainer>
          </LittleContainer>
        </BigContainer>
      </ContractInfosContainer>
    </div>
  )
}

export default ContractPage
