/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import DetailsLoaderContainer from './DetailsLoaderContainer'

/* Styled components -------------------------------------------------------- */
const CardContentContainer = styled.div`
  padding: 0px 20px 20px;
`

const BigContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 6%;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    gap: 10px;
  }
`

const BoldTitle = styled(FormBoldTitle)`
  font-size: 1.15rem;
  margin-bottom: 15px;
`

/* Component declaration ---------------------------------------------------- */
interface DetailsInfosContainerProps {
  title: string;
  children: React.ReactNode;
  isFetching: boolean;
}

const DetailsInfosContainer: React.FC<DetailsInfosContainerProps> = ({
  title,
  children,
  isFetching,
}) => {

  return (
    <Card>
      <CardContentContainer>
        <BoldTitle>
          {title}
        </BoldTitle>
        {
          isFetching ?
            <DetailsLoaderContainer isFetching /> :
            <BigContainer>
              {children}
            </BigContainer>
        }
      </CardContentContainer>
    </Card>
  )
}

export default DetailsInfosContainer
