/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { mainTabs } from 'helpers/readConfig'
import defaultMainTabs from 'helpers/defaultMainTabs'

/* Component imports -------------------------------------------------------- */
import {
  Tab,
  Tabs,
} from '@mui/material'
import RouteTitle from 'router/RouteTitle'

/* Styled components -------------------------------------------------------- */
const TabsContainer = styled(Tabs)`
  @media ${(props) => props.theme.media.desktop} {
    button {
      padding-bottom: 0px;
    }
  }

  @media ${(props) => props.theme.media.tablet}, ${(props) => props.theme.media.mobile.main} {
    padding-top: 20px;
    span {
      left: 0px;
      width: 5px;
    }
    button {
      align-items: start;
      padding-left: 20px;
    }
  }
`

/* Component declaration ---------------------------------------------------- */
interface MainLayoutNavTabsProps {
  closeDrawer: () => void;
  vertical?: boolean;
}

const MainLayoutNavTabs: React.FC<MainLayoutNavTabsProps> = ({
  closeDrawer,
  vertical,
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [ tabValue, setTabValue ] = useState<number | boolean>(false)
  const [ title, setTitle ] = useState<string>('')
  const tabs = [
    {
      label: 'Vos dossiers',
      path: 'dossiers',
    },
    ...mainTabs.map((configTab) => defaultMainTabs.find((tab) => tab.path === configTab) ?? defaultMainTabs[0]),
  ]

  useEffect(() => {
    const newTabValue = tabs.findIndex((tab) => location.pathname.indexOf(tab.path) !== -1)
    setTabValue(newTabValue)
    if (tabs[newTabValue]?.label) {
      setTitle(tabs[newTabValue].label)
    }
  }, [ location.pathname ])

  const handleTabChange = (newValue: number): void => {
    setTabValue(newValue)
    navigate(`/${tabs[newValue].path}/`)
    closeDrawer()
  }

  return (
    <>
      <TabsContainer
        value={tabValue === -1 ? false : tabValue}
        orientation={vertical ? 'vertical' : 'horizontal'}
      >
        {
          tabs.map((tab, index) => (
            <Tab
              key={`${tab.path}-${index}`}
              label={tab.label}
              onClick={() => handleTabChange(index)}
            />
          ))
        }
      </TabsContainer>
      <RouteTitle title={title} />
    </>
  )
}

export default MainLayoutNavTabs
