/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ContractLoaderContainer from './ContractLoaderContainer'

/* Styled components -------------------------------------------------------- */
const CardContentContainer = styled.div`
  padding: 0px 20px 20px;
`

const BoldTitle = styled(FormBoldTitle)`
  font-size: 1.15rem;
  margin-bottom: 15px;
`

/* Component declaration ---------------------------------------------------- */
interface ContractInfosContainerProps {
  title: string;
  children: React.ReactNode;
  isFetching: boolean;
}

const ContractInfosContainer: React.FC<ContractInfosContainerProps> = ({
  title,
  children,
  isFetching,
}) => {

  return (
    <Card>
      <CardContentContainer>
        <BoldTitle>
          {title}
        </BoldTitle>
        {
          isFetching ?
            <ContractLoaderContainer isFetching /> :
            children
        }
      </CardContentContainer>
    </Card>
  )
}

export default ContractInfosContainer
