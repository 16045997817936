/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useMobileStatusBarStyle } from 'helpers/hooks/useMobileStatusBarStyle'
import {
  onLogoutReset,
  useAuthInfo,
} from 'store/hooks'
import {
  useGetNotificationListQuery,
  useGetProfileInfosQuery,
} from 'store/api'

/* Component imports -------------------------------------------------------- */
import {
  Link,
  Menu,
  MenuItem,
  Drawer,
  Button,
  Badge,
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import ErrorBoundaryPage from 'layouts/MainLayout/ErrorBoundaryPage/ErrorBoundaryPage'
import PageContainer from 'layouts/PageContainer/PageContainer'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import GroupLogo from 'components/GroupLogo/GroupLogo'
import MiniAvatarRenderer from 'components/MediaRenderer/MiniAvatarRenderer'
import MainLayoutNavTabs from './MainLayoutComponents/MainLayoutNavTabs'
import MainLayoutSearchField from './MainLayoutComponents/MainLayoutSearchField'

/* Type imports ------------------------------------------------------------- */
import {
  LoginContextType,
  RoleEpass,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DesktopHeader = styled.div`
  display: none;

  @media ${(props) => props.theme.media.desktop} {
    display: initial;
  }
`

const MobileHeader = styled.div`
  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: end;
  overflow-x: overlay;
  overflow-y: hidden;
  z-index: 200;

  background-color: #FFFFFF;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};

  padding: 10px 0px;
  height: 50px;

  button {
    font-size: 1rem;
  }

  @media ${(props) => props.theme.media.desktop} {
    height: 70px;
    // On right side we add an additional 15px because the rest of the outlet
    // layout will have that extra padding for the possible scrollbar width.
    // If we change the scrollbar we may have to change this value
    // The rest of the calcul is to add more padding on large screens, as in PageContainer
    padding: 10px max(calc(15vw - 200px + 15px), calc(0.5rem + 15px)) 0px max(calc(15vw - 200px), 0.5rem);
  }
`

const HeaderTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;

  display: flex;
  align-items: center;
  font-size: 1.2rem;

  .loader-logo {
    max-width: 140px;
    max-height: 35px;
    margin-right: 15px;

    @media ${(props) => props.theme.media.desktop} {
      max-height: 50px;
    }
  }
`

const HeaderTitleButton = HeaderTitleContainer.withComponent(Button)

const HeaderTitleButtonContainer = styled(HeaderTitleButton)`
  height: max-content;
  align-self: center;

  @media ${(props) => props.theme.media.desktop} {
    margin-bottom: 10px;
  }
`

const SearchBar = styled.div`
  display: flex;
  padding-bottom: 15px;
  gap: 10px;
`

const DrawerContainer = styled(Drawer)`
  .MuiDrawer-paper {
    width: min(80%, 400px);
  }
`

const DrawerContent = styled.div`
  padding: 10px;
`

const MobileAccountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  padding-bottom: 10px;
`

const MobileProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const OtherPageContainer = styled.div`
  position: relative;
`

interface CustomUnderlineProps {
  visible: boolean;
}

const CustomUnderline = styled.span<CustomUnderlineProps>`
  position: absolute;
  top: 50px;
  width: ${(props) => props.visible ? '100%' : '0px'};
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #07556D;
  height: 6px;
`

/* Component declaration ---------------------------------------------------- */
interface MainLayoutProps {}

const MainLayout: React.FC<MainLayoutProps> = () => {
  useMobileStatusBarStyle('black')
  const location = useLocation()
  const navigate = useNavigate()
  const authInfo = useAuthInfo()
  const [ openDrawer, setOpenDrawer ] = useState<boolean>(false)
  const [ anchorMenu, setAnchorMenu ] = useState<null | HTMLButtonElement>(null)
  const menuOpen = Boolean(anchorMenu)

  const isExternalLogin = useMemo(() => authInfo?.currentCollaborateur?.loginContext?.type !== LoginContextType.Complete, [ authInfo ])
  const isEpassUser = useMemo(() => authInfo?.currentCollaborateur?.epassPermissions?.roleEpass === RoleEpass.Standard, [ authInfo ])

  const { currentData: notificationList = []} = useGetNotificationListQuery()
  const { currentData: profileInfos } = useGetProfileInfosQuery(undefined, { skip: isExternalLogin || !isEpassUser })

  useEffect(() => {
    // The following line is meant to change display style when we display the case layout submenus
    const mainLayoutPageContainer = document.querySelector('.main-layout-page-container')

    if (mainLayoutPageContainer?.querySelector('.case-layout-container')) {
      mainLayoutPageContainer.classList.add('display-contents')
    } else {
      mainLayoutPageContainer?.classList.remove('display-contents')
    }
  }, [ location.pathname ])

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorMenu(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorMenu(null)
  }

  const onLogout = () => {
    onLogoutReset()
  }

  const navigateToMainPage = () => {
    navigate('/')
  }

  const navigateToProfilePage = () => {
    if (isExternalLogin || !isEpassUser) {
      return
    }
    navigate('/mon-compte')
    setAnchorMenu(null)
    setOpenDrawer(false)
  }

  const navigateToNotificationsPage = () => {
    if (authInfo?.currentCollaborateur?.loginContext.type !== LoginContextType.Complete) {
      return
    }
    navigate('/notifications')
    setAnchorMenu(null)
    setOpenDrawer(false)
  }

  return (
    <BaseLayout>
      <ErrorBoundaryPage>
        <DesktopHeader>
          <HeaderContainer>
            <HeaderTitleButtonContainer onClick={navigateToMainPage}>
              <GroupLogo />
              ePass
            </HeaderTitleButtonContainer>
            {
              !isExternalLogin &&
                <>
                  <MainLayoutNavTabs closeDrawer={() => setOpenDrawer(false)} />
                  <HeaderTitleContainer>
                    <SearchBar>
                      <MainLayoutSearchField closeDrawer={() => setOpenDrawer(false)} />
                      <OtherPageContainer>
                        <Badge
                          badgeContent={notificationList.length}
                          color="error"
                          anchorOrigin={
                            {
                              vertical: 'top',
                              horizontal: 'left',
                            }
                          }
                        >
                          <CustomIconButton
                            Icon={NotificationsIcon}
                            color="primary"
                            variant="contained"
                            onClick={navigateToNotificationsPage}
                            label="Notification"
                          />
                          <CustomUnderline visible={location.pathname.indexOf('notifications') !== -1} />
                        </Badge>
                      </OtherPageContainer>
                      <OtherPageContainer>
                        <MiniAvatarRenderer
                          url={profileInfos?.urlPhotoDeProfil || ''}
                          color="primary"
                          variant="outlined"
                          onClick={handleMenuClick}
                        />
                        <CustomUnderline visible={location.pathname.indexOf('mon-compte') !== -1} />
                      </OtherPageContainer>
                      <Menu
                        anchorEl={anchorMenu}
                        open={menuOpen}
                        onClose={handleMenuClose}
                      >
                        {
                          isEpassUser &&
                            <MenuItem onClick={navigateToProfilePage}>
                              Mon compte
                            </MenuItem>
                        }
                        <MenuItem onClick={onLogout}>
                          Se déconnecter
                        </MenuItem>
                      </Menu>
                    </SearchBar>
                  </HeaderTitleContainer>
                </>
            }
            {
              isExternalLogin &&
                <Button onClick={onLogout}>
                  Me déconnecter
                </Button>
            }
          </HeaderContainer>
        </DesktopHeader>
        <MobileHeader>
          <HeaderContainer>
            <HeaderTitleButtonContainer onClick={navigateToMainPage}>
              <GroupLogo />
              ePass
            </HeaderTitleButtonContainer>
            {
              !isExternalLogin &&
                <HeaderTitleContainer>
                  <CustomIconButton
                    Icon={MenuIcon}
                    color="primary"
                    onClick={() => setOpenDrawer(!openDrawer)}
                    label="Menu"
                  />
                </HeaderTitleContainer>
            }
            {
              isExternalLogin &&
                <Button onClick={onLogout}>
                  Me déconnecter
                </Button>
            }
          </HeaderContainer>
          {
            !isExternalLogin &&
              <DrawerContainer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                anchor="right"
              >
                <DrawerContent>
                  <MobileAccountContainer>
                    <MobileProfileContainer>
                      <MiniAvatarRenderer
                        url={profileInfos?.urlPhotoDeProfil || ''}
                        color="primary"
                        variant="outlined"
                        onClick={navigateToProfilePage}
                      />
                      <div>
                        {authInfo?.currentCollaborateur?.nom}
                        <br />
                        <Link onClick={onLogout}>
                          Se déconnecter
                        </Link>
                      </div>
                    </MobileProfileContainer>
                    <CustomIconButton
                      Icon={NotificationsIcon}
                      color="primary"
                      variant="contained"
                      onClick={navigateToNotificationsPage}
                      label="Notification"
                    />
                  </MobileAccountContainer>
                  <MainLayoutSearchField closeDrawer={() => setOpenDrawer(false)} />
                  <MainLayoutNavTabs
                    closeDrawer={() => setOpenDrawer(false)}
                    vertical
                  />
                </DrawerContent>
              </DrawerContainer>
          }
        </MobileHeader>
        <PageContainer className="main-layout-page-container">
          <Outlet />
        </PageContainer>
      </ErrorBoundaryPage>
    </BaseLayout>
  )
}

export default MainLayout
