/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { SubdirectoryArrowRightRounded } from '@mui/icons-material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import ExpertiseRdvDownloadButton from './ExpertiseRdvDownloadButton'
import {
  Chip,
  Infos,
  Person,
  Role,
} from './ExpertiseRdvLineComponents'

/* Type imports ------------------------------------------------------------- */
import type { IntervenantParticipant } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const NameContainer = styled.div`
  display: flex;
  align-items: start;
`

const InfoContainer = styled(Infos)`
  @media ${(props) => props.theme.media.mobile.main} {
    padding-top: 10px;
  }
`

const RoleContainer = styled(Role)`
  @media ${(props) => props.theme.media.mobile.main} {
    padding-left: 30px;
  }
`

const ChipContainer = styled(Chip)`
  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: 10px;
  }
`

const SubIcon = styled(SubdirectoryArrowRightRounded)`
  font-size: 2rem;
  color: ${(props) => props.theme.palette.secondary.main};
  margin-top: -10px;

  @media ${(props) => props.theme.media.mobile.main} {
    font-size: 1.8rem;
    margin-top: -7px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ExpertiseRdvStakeholderLineProps {
  person: IntervenantParticipant;
}

const ExpertiseRdvStakeholderLine: React.FC<ExpertiseRdvStakeholderLineProps> = ({ person }) => {

  return (
    <Person>
      <InfoContainer>
        <NameContainer>
          <SubIcon />
          {`${person.nom} ${person.prenom}`}
        </NameContainer>
        <RoleContainer>
          <div>
            {`Rôle : ${person.role}`}
          </div>
          <div>
            {`Ref : ${person.reference}`}
          </div>
        </RoleContainer>
        <ExpertiseRdvDownloadButton
          url={person.convocation.url}
          name={person.nom}
          sub
        />
      </InfoContainer>
      <ChipContainer>
        <ColoredSquareChip
          color={person.convocation.present? 'green' : 'red'}
          smaller
        >
          {person.convocation.present? 'Présent' : 'Non présent'}
        </ColoredSquareChip>
      </ChipContainer>
    </Person>
  )
}

export default ExpertiseRdvStakeholderLine
