/* Component imports -------------------------------------------------------- */
import CaseFollowUpPage from 'pages/CasePages/CaseFollowUpPage/CaseFollowUpPage'
import DetailsPage from 'pages/CasePages/DetailsPage/DetailsPage'
import ExpertiseRdvPage from 'pages/CasePages/ExpertiseRdvPage/ExpertiseRdvPage'
import DocumentsPage from 'pages/CasePages/DocumentsPage/DocumentsPage'
import ContractPage from 'pages/CasePages/ContractPage/ContractPage'
import StakeholdersPage from 'pages/CasePages/StakeholdersPage/StakeholdersPage'
import MessagesPage from 'pages/CasePages/MessagesPage/MessagesPage'

/* Component declaration ---------------------------------------------------- */
interface CaseTab {
  label: string;
  path: string;
  component: React.ComponentType<{props: object}>;
}

const defaultCaseTabs: CaseTab[] = [
  {
    label: 'Suivi dossier',
    path: 'suivi-dossier',
    component: CaseFollowUpPage,
  },
  {
    label: 'Détails',
    path: 'details',
    component: DetailsPage,
  },
  {
    label: 'RDV expertise',
    path: 'rdv-expertise',
    component: ExpertiseRdvPage,
  },
  {
    label: 'Documents',
    path: 'documents',
    component: DocumentsPage,
  },
  {
    label: 'Contrat',
    path: 'contrat',
    component: ContractPage,
  },
  {
    label: 'Intervenants',
    path: 'intervenants',
    component: StakeholdersPage,
  },
  {
    label: 'Messages',
    path: 'messages',
    component: MessagesPage,
  },
]

export default defaultCaseTabs
