/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { setAuthInfo } from 'store/slices/authSlice'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import RouteTitle from 'router/RouteTitle'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthErrorMessage from 'components/AuthComponents/AuthErrorMessage'

/* Type imports ------------------------------------------------------------- */
import {
  CurrentCollaborateurType,
  LoginContextType,
  RoleEpass,
  type LoginResponse,
} from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface Nomad9LoginPageProps {}

const Nomad9LoginPage: React.FC<Nomad9LoginPageProps> = () => {
  const { caseId } = useParams<{ caseId: string }>()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null)

  useEffect(() => {
    const newLogin: LoginResponse = {
      currentCollaborateur: {
        id: '',
        nom: 'Nomad9',
        numAgence: 0,
        refAnnuaire: { code: '', famille: '', professionnel: false, refComplete: '' },
        currentCollaborateurType: CurrentCollaborateurType.Nomad,
        loginContext: { type: LoginContextType.Dossier, refDossier: { annee: '', source: '', numero: '', refComplete: caseId || '' }},
        epassPermissions: {
          roleEpass: RoleEpass.Exterieur,
        },
        isSuperAdmin: false,
        userName: '',
      },
      token: location.search.split('?token=')[1],
    }

    if (isValidString(location.search.split('?token=')[1])) {
      dispatch(setAuthInfo(newLogin))
      navigate(`/dossiers/${caseId}`)
    } else {
      setErrorMessage('Erreur lors de la connexion : url invalide.')
    }
  }, [ location ])

  return (
    <BaseLayout className="AuthLayout">
      <RouteTitle title="Connexion Nomad9" />
      <AuthContainer>
        <AuthWelcomeMessage>
          {'Bienvenue dans '}
          <b>
            ePass
          </b>
        </AuthWelcomeMessage>
        {
          isValidString(errorMessage) && (
            <AuthErrorMessage>
              {errorMessage}
            </AuthErrorMessage>
          )
        }
      </AuthContainer>
    </BaseLayout>
  )
}

export default Nomad9LoginPage
