/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'

/* Styled components -------------------------------------------------------- */
const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

/* Component declaration ---------------------------------------------------- */
interface DetailsLoaderContainerProps {
  isFetching: boolean;
}

const DetailsLoaderContainer: React.FC<DetailsLoaderContainerProps> = ({ isFetching }) => {

  return (
    <LoaderContainer>
      {isFetching && <CircularProgress />}
    </LoaderContainer>
  )
}

export default DetailsLoaderContainer
