/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import {
  ArrowUpwardRounded,
  CircleOutlined,
} from '@mui/icons-material'

/* Type imports ------------------------------------------------------------- */
import type { Order } from 'types/Table'

/* Styled components -------------------------------------------------------- */
const CheckableSortingButtonContainer = styled.div`
  align-self: end;
  height: fit-content;
  min-height: 50px;
  margin-top: 5px;
`

interface CheckableFormControlLabelProps {
  checked: boolean;
  disabled: boolean;
}

const CheckableFormControlLabel = styled(FormControlLabel)<CheckableFormControlLabelProps>`
  border: 1px solid ${(props) => props.theme.colors.grey};
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  margin: 0;
  color: ${(props) => props.checked ? props.theme.colors.main : props.theme.palette.primary.main};
  background-color: ${(props) => props.checked ? props.disabled ? props.theme.palette.info.dark : props.theme.palette.primary.main : props.theme.colors.main};
`

const BlueCheckbox = styled(Checkbox)<CheckableFormControlLabelProps>`
  svg {
    fill: ${(props) => props.checked ? undefined : props.disabled ? props.theme.palette.info.dark : props.theme.palette.primary.main};
  }
  height: 38px;
  width: 38px;
`

interface OrderIconProps {
  order: Order;
}

const OrderIcon = styled(ArrowUpwardRounded)<OrderIconProps>`
  transform: scaleY(${(props) => props.order === 'asc' ? 1 : -1});
  font-size: 28px;
`

/* Component declaration ---------------------------------------------------- */
interface CheckableSortingButtonProps {
  checked: boolean;
  onChange: (event: React.SyntheticEvent, checked: boolean) => void;
  label: React.ReactNode;
  className?: string;
  disabled?: boolean;
  order: Order;
}

const CheckableSortingButton: React.FC<CheckableSortingButtonProps> = ({
  checked,
  onChange,
  label,
  className,
  disabled = false,
  order,
}) => {
  return (
    <CheckableSortingButtonContainer className={className}>
      <CheckableFormControlLabel
        checked={checked}
        onChange={onChange}
        label={label}
        disabled={disabled}
        control={
          <BlueCheckbox
            icon={
              <CircleOutlined
                color="primary"
                fontSize="small"
              />
            }
            checked={checked}
            checkedIcon={
              <OrderIcon
                order={order}
                color="info"
              />
            }
            disabled={disabled}
          />
        }
      />
    </CheckableSortingButtonContainer>
  )
}

export default CheckableSortingButton
