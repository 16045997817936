/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  MenuItem,
  Select,
} from '@mui/material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import EraseFiltersButton from 'components/EraseFiltersButton/EraseFiltersButton'

/* Type imports ------------------------------------------------------------- */
import type {
  CodeLabel,
  TypeSuivi,
} from 'API/__generated__/Api'
import type { FollowUpFilters } from '../CaseFollowUpPage'

/* Styled components -------------------------------------------------------- */
const SelectFilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 10px));
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: -20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseFollowUpFiltersProps {
  size?: 'small' | 'medium';
  filters: FollowUpFilters;
  setFilters: (filters: FollowUpFilters) => void;
  eventTypeList: CodeLabel[];
  recipientList: string[];
  onEraseFilterClick: () => void;
}

const CaseFollowUpFilters: React.FC<CaseFollowUpFiltersProps> = ({
  size = undefined,
  filters,
  setFilters,
  eventTypeList,
  recipientList,
  onEraseFilterClick,
}) => {

  return (
    <SelectFilterContainer>
      <div>
        <FormBoldTitle>
          Type d'évènement
        </FormBoldTitle>
        <Select
          value={filters.eventType}
          onChange={(e) => setFilters({ ...filters, eventType: e.target.value as TypeSuivi })}
          displayEmpty
          size={size}
        >
          <MenuItem value="">
            Sélectionner
          </MenuItem>
          {
            eventTypeList.map((value, index) => (
              <MenuItem
                value={value.code}
                key={`${value.code}-${index}`}
              >
                {value.libelle}
              </MenuItem>
            ),
            )
          }
        </Select>
      </div>
      <div>
        <FormBoldTitle>
          Destinataire
        </FormBoldTitle>
        <Select
          value={filters.recipient}
          onChange={(e) => setFilters({ ...filters, recipient: e.target.value })}
          displayEmpty
          size={size}
        >
          <MenuItem value="">
            Sélectionner
          </MenuItem>
          {
            recipientList.map((value, index) => (
              <MenuItem
                value={value}
                key={`${value}-${index}`}
              >
                {value}
              </MenuItem>
            ),
            )
          }
        </Select>
      </div>
      <div>
        <FormBoldTitle>
          Évènement avec PJ
        </FormBoldTitle>
        <Select
          value={filters.withDocuments}
          onChange={(e) => setFilters({ ...filters, withDocuments: e.target.value })}
          displayEmpty
          size={size}
        >
          <MenuItem value="">
            Sélectionner
          </MenuItem>
          {
            [
              { label: 'Avec PJ seulement', value: 'true' },
              { label: 'Sans PJ seulement', value: 'false' },
            ].map((value, index) => (
              <MenuItem
                value={value.value}
                key={`${value.value}-${index}`}
              >
                {value.label}
              </MenuItem>
            ),
            )
          }
        </Select>
      </div>
      <EraseFiltersButton onClick={onEraseFilterClick} />
    </SelectFilterContainer>
  )
}

export default CaseFollowUpFilters
