/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'

/* Type declarations ----------------------------------- */
export interface CaseListFilterState {
  period: number;
}

/* CaseListFilter Redux slice ---------------------------- */
const caseListFilterSlice = createSlice(
  {
    name: 'caseListFilter',
    initialState: {
      period: 30,
    } as CaseListFilterState,
    reducers: {
      setCaseListPeriod: (state, { payload }: PayloadAction<number>) => {
        state.period = payload
      },
    },
  },
)

/* Export slice components ----------------------------- */
export const { setCaseListPeriod } = caseListFilterSlice.actions

export default caseListFilterSlice.reducer

export const getCaseListPeriod = (state: RootState): number => {
  return state.caseListFilter.period
}
