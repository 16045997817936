/* Framework imports -------------------------------------------------------- */
import React from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostResetPasswordChangeMutation } from 'store/api'
import { isValidString } from 'helpers/isValidString'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthInputContainer from 'components/AuthComponents/AuthInputContainer'
import AuthButtonsContainer from 'components/AuthComponents/AuthButtonsContainer'
import FormikPasswordInput from 'components/AuthComponents/FormikPasswordInput'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { ApiResponse } from 'helpers/fetchHelpers'

/* Type declarations -------------------------------------------------------- */
interface Password {
  newPassword: string;
  newPasswordRepeat: string;
}

const LoginFormSchema = Yup.object().shape({
  newPassword: Yup.string().max(40, 'Le mot de passe ne doit pas dépasser 40 caractères.'),
  newPasswordRepeat: Yup.string().when('newPassword', {
    is: (newPassword: string) => isValidString(newPassword),
    then: (schema) => schema.oneOf([ Yup.ref('newPassword') ], 'Les mots de passe ne correspondent pas').required('Les mots de passe ne correspondent pas'),
  }),
})

/* Component declaration ---------------------------------------------------- */
interface ResetPasswordPageProps {}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [
    changePassword,
  ] = usePostResetPasswordChangeMutation()

  const onSubmit = (values: Password, { setSubmitting }: FormikHelpers<Password> ): void | Promise<void> => {
    const onFinish = (response: ApiResponse<void>) => {
      setSubmitting(false)
      if (!isApiError(response)) {
        toast.success(`Le mot de passe à bien été modifié.`)
        navigate('/connexion')
      } else {
        toast.error(`Une erreur est survenue lors de la modification du mot de passe. Veuillez réessayer avec un nouveau lien.`)
      }
    }

    const query = location.search.substring(1)

    const vars = query.split('&')
    let token = ''
    vars.forEach((vari) => {
      const pair = vari.split('=')
      if (decodeURIComponent(pair[0]) === 'token') {
        token = decodeURIComponent(pair[1])
      }
    })

    changePassword({ newPassword: values.newPassword, token }).then(onFinish).catch(console.error)
  }

  const formikForm = useForm<Password>(
    {
      initialValues: {
        newPassword: '',
        newPasswordRepeat: '',
      },
      onSubmit: onSubmit,
      validationSchema: LoginFormSchema,
    },
  )

  const onCancelClick = () => {
    navigate('/connexion')
  }

  return (
    <AuthContainer>
      <Form form={formikForm}>
        {formikForm.isSubmitting && <Loader />}
        <AuthWelcomeMessage>
          Nouveau mot de passe
        </AuthWelcomeMessage>
        <div>
          <AuthInputContainer>
            <FormBoldTitle>
              Nouveau mot de passe
            </FormBoldTitle>
            <FormikPasswordInput
              placeholder="Nouveau mot de passe"
              name="newPassword"
              autoComplete="new-password"
            />
          </AuthInputContainer>
          <AuthInputContainer>
            <FormBoldTitle>
              Confirmer le nouveau mot de passe
            </FormBoldTitle>
            <FormikPasswordInput
              placeholder="Nouveau mot de passe"
              name="newPasswordRepeat"
              autoComplete="new-password"
            />
          </AuthInputContainer>
        </div>
        <AuthButtonsContainer>
          <Button
            type="submit"
            disabled={formikForm.isSubmitting}
            variant="contained"
            fullWidth
          >
            {
              formikForm.isSubmitting ?
                `Modification en cours...` :
                `Valider`
            }
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={formikForm.isSubmitting}
            onClick={onCancelClick}
          >
            Annuler
          </Button>
        </AuthButtonsContainer>
      </Form>
    </AuthContainer>
  )
}

export default ResetPasswordPage
