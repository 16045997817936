/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { useGetCaseInfosQuery } from 'store/api'
import { getAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material'
import { SubdirectoryArrowRightRounded } from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'

/* Type imports ------------------------------------------------------------- */
import { TypePersonne } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface CardContainerProps {
  last: string;
}

const CardContainer = styled(Card)<CardContainerProps>`
  margin-bottom: ${(props) => props.last === 'true' ? '0px' : '20px'};
  font-size: .95rem;

  @media ${(props) => props.theme.media.mobile.main} {
    font-size: .9rem;
  }
`

interface SubProps {
  sub?: boolean;
}

const NameContainer = styled.div<SubProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.sub ? '0px' : '5px'};
  gap: 25px;

  b {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
    gap: 5px;
    align-items: start;
    margin-bottom: 2px;

    .role {
      padding-left: 30px;
    }

    b {
      font-size: .95rem;
    }
  }
`

const InfosContainer = styled.div<SubProps>`
  display: flex;
  gap: 20px;
  padding-left: ${(props) => props.sub ? '40px' : 0};

  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
    gap: 0px;
    padding-left: ${(props) => props.sub ? '30px' : 0};
  }
`

const TelEmailContainer = styled.div`
  flex: 0 0 fit-content;
`

const SubIcon = styled(SubdirectoryArrowRightRounded)`
  font-size: 2.2rem;
  color: ${(props) => props.theme.palette.secondary.main};
  margin-top: -10px;

  @media ${(props) => props.theme.media.mobile.main} {
    font-size: 1.8rem;
  }
`

interface BoldSeparatorProps {
  big?: boolean;
}

const BoldSeparator = styled.div<BoldSeparatorProps>`
  margin: 15px -15px 20px;
  border-bottom: ${(props) => props.big ? '2px' : '1px'} solid ${(props) => props.theme.colors.grey};
`

/* Component declaration ---------------------------------------------------- */
interface StakeholdersPageProps {}

const StakeholdersPage: React.FC<StakeholdersPageProps> = () => {
  const { caseId } = useParams<{ caseId: string }>()

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId || '')

  const getPersonName = (lastName?: string | null, firstName?: string | null): string => {
    return [ lastName, firstName ].join(' ')
  }

  return (
    <div>
      <LargeTitle>
        Intervenants
      </LargeTitle>
      {isFetchingCaseInfos && <CircularProgress />}
      {
        caseInfos?.personnes
          ?.filter((person) => person.type !== TypePersonne.Assure)
          ?.map((person, index) => (
            <CardContainer
              key={`${person.id}-${index}`}
              last={(index === (caseInfos?.personnes?.length || 0) - 1) ? 'true' : 'false'}
            >
              <CardContent>
                <NameContainer>
                  <b>
                    {`${getPersonName(person.nom, person.prenom)} - Tiers ${index + 1}`}
                  </b>
                  {`Qualité : ${person.qualite?.libelle || ''}`}
                </NameContainer>
                <InfosContainer>
                  <div>
                    {getAddress(person.adresse)}
                  </div>
                  <TelEmailContainer>
                    <a
                      href={`tel:${person?.tel}`}
                      target="_self"
                    >
                      {person?.tel}
                    </a>
                  </TelEmailContainer>
                  <TelEmailContainer>
                    <a
                      href={`mailto:${person?.email}`}
                      target="_self"
                    >
                      {person?.email}
                    </a>
                  </TelEmailContainer>
                </InfosContainer>
                {
                  person.intervenants.length > 0 &&
                    <BoldSeparator big />
                }
                {
                  person.intervenants.map((stakeholder, stakeholderIndex) => (
                    <div key={`${stakeholder.id}-${index}-${stakeholderIndex}`}>
                      <NameContainer sub>
                        <b>
                          <SubIcon />
                          {`${getPersonName(stakeholder.nom, '')}`}
                        </b>
                        <div className="role">
                          {`Rôle : ${stakeholder.role || ''}`}
                        </div>
                      </NameContainer>
                      <InfosContainer sub>
                        <div>
                          {getAddress(stakeholder.adresse)}
                        </div>
                        <TelEmailContainer>
                          <a
                            href={`tel:${stakeholder?.tel}`}
                            target="_self"
                          >
                            {stakeholder?.tel}
                          </a>
                        </TelEmailContainer>
                        <TelEmailContainer>
                          <a
                            href={`mailto:${stakeholder?.email}`}
                            target="_self"
                          >
                            {stakeholder?.email}
                          </a>
                        </TelEmailContainer>
                      </InfosContainer>
                      {
                        stakeholderIndex !== person.intervenants.length -1 &&
                          <BoldSeparator />
                      }
                    </div>
                  ))
                }
              </CardContent>
            </CardContainer>
          ))
      }
    </div>
  )
}

export default StakeholdersPage
