/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useParams,
} from 'react-router-dom'
import {
  useGetCaseKeyDatesQuery,
  useGetCaseInfosQuery,
  useGetCaseDocumentInfosListQuery,
  useGetCaseWorkflowQuery,
  useGetGroupInfosQuery,
  useGetCaseFilteredEventDocumentListQuery,
} from 'store/api'
import { useIsDesktop } from 'helpers/hooks/useIsMedia'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material'
import CaseLayoutTitleStatus from './CaseLayoutComponents/CaseLayoutTitleStatus'
import CaseLayoutNavTabs from './CaseLayoutComponents/CaseLayoutNavTabs'
import CaseLayoutSummary from './CaseLayoutComponents/CaseLayoutSummary'

/* Styled components -------------------------------------------------------- */
const CaseLayoutContainer = styled.div`
  background-color: #F6F8FD;
  flex-grow: 1;
  flex-shrink: 1;

  @media ${(props) => props.theme.media.desktop}, ${(props) => props.theme.media.tablet} {
    display: contents;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .outlet-scrollable-content {
    overflow-x: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    background-color: #F6F8FD;
    padding: ${(props) => props.theme.layoutPadding.main};

    @media ${(props) => props.theme.media.mobile.main} {
      overflow-y: hidden;
    }

    @media ${(props) => props.theme.media.desktop} {
      // Add padding on sides the larger the screen is
      padding: ${(props) => props.theme.layoutPadding.desktop};
      padding-top: 0px;
    }
  }

  .mobile-menu-tabs {
    border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  }
`

const CardDesktopContainer = styled(Card)`
  display: none;

  @media ${(props) => props.theme.media.desktop} {
    z-index: 100;
    display: block;
    margin-bottom: 30px;
    position: sticky;
    top: 0px;
  }
`

const CardMobileContainer = styled.div`
  background-color: #FFFFFF;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  padding-top: 5px;

  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const CardContentContainer = styled.div`
  padding: 15px 15px 0px;
`

const CaseStatus = styled.div`
  padding-bottom: 5px;
`

const CaseTitleStatusMobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-bottom: 2px solid #E2E8F1;
  padding: 4px 10px 8px 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 5px;
  }
`

const OutletDesktopContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.desktop} {
    display: flex;
    gap: 30px;
    & > div:nth-of-type(2) {
      width: 100%;
    }
  }
`

const OutletMobileContainer = styled.div`
  display: block;
  padding-top: 10px;

  @media ${(props) => props.theme.media.desktop} {
    display: none;
  }
`

const ResumeCardContainer = styled(Card)`
  display: none;
  width: 30vw !important;

  @media ${(props) => props.theme.media.desktop} {
    display: initial;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    position: sticky;
    top: 0px;
    font-size: .9rem;
    height: fit-content;
  }
`

const BlankDiv = styled.div`
  @media ${(props) => props.theme.media.desktop} {
    height: 30px;
    background-color: #F6F8FD;
    flex-shrink: 0;
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutProps {
  externalLogin?: boolean;
}

const CaseLayout: React.FC<CaseLayoutProps> = ({ externalLogin = false }) => {
  const isDesktop = useIsDesktop()
  const { caseId } = useParams<{ caseId: string }>()
  const [ mobileMenuTabValue, setMobileMenuTabValue ] = useState<number>(1)

  const {
    currentData: caseInfos,
    isFetching: isFetchingCaseInfos,
  } = useGetCaseInfosQuery(caseId || '')
  const {
    currentData: keyDates,
    isFetching: isFetchingKeyDates,
  } = useGetCaseKeyDatesQuery(caseId || '')
  const {
    currentData: workflow = [],
    isFetching: isFetchingWorkflow,
  } = useGetCaseWorkflowQuery(caseId || '')
  const {
    currentData: eventDocuments = [],
    isFetching: isFetchingEventDocuments,
  } = useGetCaseFilteredEventDocumentListQuery(caseId || '')
  const { currentData: groupInfos } = useGetGroupInfosQuery()
  const { currentData: documentInfosList = []} = useGetCaseDocumentInfosListQuery({ dossiers: [ caseId || '' ]})

  const isLoading: boolean = useMemo(() => isFetchingEventDocuments || isFetchingCaseInfos || isFetchingKeyDates, [
    isFetchingEventDocuments,
    isFetchingCaseInfos,
    isFetchingKeyDates,
  ])

  return (
    <CaseLayoutContainer className="case-layout-container">
      <CardMobileContainer>
        <CaseTitleStatusMobileContainer>
          <CaseLayoutTitleStatus
            caseId={caseId || ''}
            status={caseInfos?.etat}
            externalLogin={externalLogin}
          />
        </CaseTitleStatusMobileContainer>
        <Tabs
          value={mobileMenuTabValue}
          onChange={(e, newValue: number) => setMobileMenuTabValue(newValue)}
          className={`${mobileMenuTabValue === 1 ? 'mobile-menu-tabs' : ''}`}
          variant="fullWidth"
        >
          {
            [ 'En bref', 'Toutes les infos' ].map((tab, index) => (
              <Tab
                key={`${tab}-${index}`}
                label={tab}
              />
            ))
          }
        </Tabs>
        {
          mobileMenuTabValue === 1 &&
            <CaseLayoutNavTabs documentsLength={documentInfosList[0]?.nombreMedia ?? -1} />
        }
      </CardMobileContainer>
      <BlankDiv />
      <div className="outlet-scrollable-content">
        {
          isDesktop ?
            <OutletDesktopContainer>
              <ResumeCardContainer>
                <CardContent>
                  <CaseStatus>
                    <CaseLayoutTitleStatus
                      caseId={caseId || ''}
                      status={caseInfos?.etat}
                      externalLogin={externalLogin}
                    />
                  </CaseStatus>
                  {isLoading && <CircularProgress color="secondary" />}
                  {
                    !isLoading &&
                      <CaseLayoutSummary
                        groupInfos={groupInfos}
                        summary={caseInfos}
                        keyDates={keyDates}
                        documents={eventDocuments}
                        workflow={workflow}
                        isFetchingWorkflow={isFetchingWorkflow}
                      />
                  }
                </CardContent>
              </ResumeCardContainer>
              <div>
                <CardDesktopContainer>
                  <CardContentContainer>
                    <CaseLayoutNavTabs documentsLength={documentInfosList[0]?.nombreMedia ?? -1} />
                  </CardContentContainer>
                </CardDesktopContainer>
                <Outlet />
              </div>
            </OutletDesktopContainer> :
            <OutletMobileContainer>
              {
                mobileMenuTabValue === 0 ?
                  (
                    isLoading ?
                      <LoaderContainer>
                        <CircularProgress size={60} />
                      </LoaderContainer> :
                      <CaseLayoutSummary
                        groupInfos={groupInfos}
                        summary={caseInfos}
                        keyDates={keyDates}
                        documents={eventDocuments}
                        workflow={workflow}
                        isFetchingWorkflow={isFetchingWorkflow}
                      />
                  ) :
                  (
                    <Outlet />
                  )
              }
            </OutletMobileContainer>
        }
      </div>
    </CaseLayoutContainer>
  )
}

export default CaseLayout
