/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { setAuthInfo } from 'store/slices/authSlice'
import { useAuthenticateGexsiMutation } from 'store/api'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import RouteTitle from 'router/RouteTitle'
import BaseLayout from 'layouts/BaseLayout/BaseLayout'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthErrorMessage from 'components/AuthComponents/AuthErrorMessage'

/* Type imports ------------------------------------------------------------- */
import type { LoginResponse } from 'API/__generated__/Api'
import type { ApiResponse } from 'helpers/fetchHelpers'
import { isApiResponse } from 'helpers/fetchHelpers'

/* Styled components -------------------------------------------------------- */
const Message = styled.div`
  text-align: center;
`

/* Component declaration ---------------------------------------------------- */
interface GexsiLoginPageProps {}

const GexsiLoginPage: React.FC<GexsiLoginPageProps> = () => {
  const { caseId } = useParams<{ caseId: string }>()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(true)
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null)

  const [
    tryLogin,
  ] = useAuthenticateGexsiMutation()

  useEffect(() => {
    const query = location.search.substring(1)

    const vars = query.split('&')
    const params: { [key: string]: string | undefined } = { session: undefined, cleAnnuaire: undefined, contexte: undefined, origineMission: undefined }
    vars.forEach((vari) => {
      const pair = vari.split('=')
      Object.keys(params).forEach((keyName) => {
        if (decodeURIComponent(pair[0]) === keyName) {
          params[keyName] = decodeURIComponent(pair[1])
        }
      })
    })

    if (isValidString(params.session)) {
      tryLogin(params)
        .then((pResult: ApiResponse<LoginResponse>) => {
          setIsSubmitting(false)

          if (isApiResponse<LoginResponse>(pResult)) {
            dispatch(setAuthInfo((pResult.data)))
            navigate(`/dossiers/${caseId}`)
          } else {
            setErrorMessage('Erreur lors de la connexion.')
          }
        })
        .catch((error) => {
          setIsSubmitting(false)
          setErrorMessage('Erreur lors de la connexion.')
          console.error(error)
        })
    } else {
      setErrorMessage('Erreur lors de la connexion : url invalide.')
    }
  }, [ location ])

  return (
    <BaseLayout className="AuthLayout">
      <RouteTitle title="Connexion Gexsi" />
      <AuthContainer>
        <AuthWelcomeMessage>
          {'Bienvenue dans '}
          <b>
            ePass
          </b>
        </AuthWelcomeMessage>
        {
          isSubmitting &&
            <Message>
              Veuillez patienter pendant que nous vous connectons automatiquement...
              <br />
              <br />
              <CircularProgress />
            </Message>
        }
        {
          isValidString(errorMessage) && (
            <AuthErrorMessage>
              {errorMessage}
            </AuthErrorMessage>
          )
        }
      </AuthContainer>
    </BaseLayout>
  )
}

export default GexsiLoginPage
