/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetAgencyListQuery,
  useGetCabinetListQuery,
  useGetCompanyListQuery,
  useGetDisasterNatureListQuery,
  useGetExpertListQuery,
  useGetExpertiseCaractereListQuery,
  useGetExpertiseNatureListQuery,
  useGetMissionTypeQuery,
  usePostDocumentsMutation,
  usePostNewMissionMutation,
} from 'store/api'
import { handleNumberVerification } from 'helpers/numberUtils'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { enumToSegmentedButtonOptions } from 'helpers/enumToSegmentedButtonOptions'
import {
  isApiError,
  isApiResponse,
} from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CircularProgress,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  MoreVertRounded,
  TaskRounded,
} from '@mui/icons-material'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import { TypeExpertise } from 'API/__generated__/Api'
import type {
  CreationDossierSinistreRequest,
  CreationDossierRequest,
  CreationDossierPersonneRequest,
  CreationDossierCabinetRequest,
  CreationDossierExpertiseRequest,
  RefDossier,
  DocumentDossierCreatePayload,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const newMissionSchema = Yup.object().shape<Shape<CreationDossierRequest>>({
  compagnie: Yup.string().required('La compagnie est obligatoire'),
  numeroPolice: Yup.string().required('Le numéro de police est obligatoire'),
  assure: Yup.object().shape<Shape<CreationDossierPersonneRequest>>({
    nom: Yup.string().required('Le nom est obligatoire'),
    codePostal: Yup.string().required('Le code postal est obligatoire'),
    ville: Yup.string().required('La ville est obligatoire'),
  }).required(),
  tiers: Yup.array(Yup.object().shape<Shape<CreationDossierPersonneRequest>>({
    nom: Yup.string().required('Le nom est obligatoire'),
    codePostal: Yup.string().required('Le code postal est obligatoire'),
    ville: Yup.string().required('La ville est obligatoire'),
  })).required(),
  sinistre: Yup.object().shape<Shape<CreationDossierSinistreRequest>>({
    numero: Yup.string().required('Le numéro de sinistre est obligatoire'),
    nature: Yup.string().required('La nature est obligatoire'),
    date: Yup.string().nullable(),
    codePostal: Yup.string().required('Le code postal est obligatoire'),
    ville: Yup.string().required('La ville est obligatoire'),
  }).required(),
  expertise: Yup.object().shape<Shape<CreationDossierExpertiseRequest>>({
    type: Yup.mixed<TypeExpertise>().required(),
    typeEAD: Yup.string().nullable().when('type', {
      is: TypeExpertise.EAD,
      then: (schema) => schema.nullable().required('Le type EAD est obligatoire dans ce cas'),
    }),
  }),
  cabinetEtAgence: Yup.object().shape<Shape<CreationDossierCabinetRequest>>({
    cabinet: Yup.string().required('Le cabinet est obligatoire'),
    agence: Yup.string().required("L'agence est obligatoire"),
    expert: Yup.string().required("L'expert est obligatoire"),
  }).required(),
}).required()

type NewMissionForm = FormikContextType<CreationDossierRequest>

/* Styled components -------------------------------------------------------- */
const CardContentContainer = styled.div`
  padding: 0px 20px 20px;
`

const BoldTitle = styled(FormBoldTitle)`
  font-size: 1.15rem;
  margin-bottom: 15px;
`

const CardContainer = styled(Card)`
  margin-top: 20px;
`

const InfoContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`

const FileInfoContainer = styled(InfoContainer)`
  flex-wrap: wrap;
`

const FieldContainer = styled.div`
  width: 100%;
`

const AddressSeparator = styled.div`
  height: 10px;
`

const DatePicker = styled(FormikDatePicker)`
  .MuiInputBase-root {
    height: 40px;
  }
`

const PriceFieldContainer = styled(PriceField)`
  .MuiInputBase-root {
    width: 50%;
  }
`

const FileContainer = styled.div`
  display: flex;
  border: 1px solid lightgray;
  border-radius: 4px;
  min-width: 150px;
  height: 40px;
  align-items: center;
`

const FileNameContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-right: auto;
  margin-left: 5px;
`

const EditButton = styled(Button)`
  min-width: 30px;
  width: 30px;
`

/* Component declaration ---------------------------------------------------- */
interface NewMissionPageProps {}

const NewMissionPage: React.FC<NewMissionPageProps> = () => {
  const [ files, setFiles ] = useState<File[]>([])
  const [ fileIndex, setFileIndex ] = useState<number>(0)
  const [ anchorMenu, setAnchorMenu ] = useState<null | HTMLButtonElement>(null)
  const menuOpen = Boolean(anchorMenu)

  const defaultPerson: CreationDossierPersonneRequest = {
    nom: '',
    prenom: '',
    telephoneMobile: '',
    telephoneFixe: '',
    adresse1: '',
    adresse2: '',
    adresse3: '',
    codePostal: '',
    ville: '',
  }

  const [
    submitNewMission,
  ] = usePostNewMissionMutation()
  const [
    submitNewDocuments,
  ] = usePostDocumentsMutation()

  const onSubmit = async (values: CreationDossierRequest, { setSubmitting, resetForm }: FormikHelpers<CreationDossierRequest>): Promise<void> => {
    const response = await submitNewMission(values)

    if (isApiResponse<RefDossier>(response)) {
      toast.success('La nouvelle mission a bien été envoyée.')
      const data: DocumentDossierCreatePayload = { requests: []}

      if (files.length > 0) {
        files.forEach((file) => {
          if (file !== undefined) {
            data.requests?.push({
              fichier: file,
              nom: file.name,
            })
          }
        })

        submitNewDocuments({
          caseId: response.data.refComplete,
          data,
        }).then((response) => {
          if (!isApiError(response)) {
            toast.success('Vos fichiers ont bien été envoyés.')
            setFiles([])
          } else {
            toast.error("Une erreur est survenue lors de l'envoi des pièces jointes.")
            setSubmitting(false)
          }
        }).catch(console.error)
      }
      resetForm()
    } else {
      toast.error("Une erreur est survenue lors de l'envoi de la mission.")
      setSubmitting(false)
    }
  }

  const formikForm: NewMissionForm = useForm<CreationDossierRequest>(
    {
      initialValues: {
        compagnie: '',
        numeroPolice: '',
        typeMission: '',
        causeEtCirconstances: '',
        dommage: {
          montant: 0,
          commentaire: '',
        },
        assure: defaultPerson,
        tiers: [],
        sinistre: {
          numero: '',
          nature: '',
          date: '',
          nom: '',
          adresse1: '',
          adresse2: '',
          codePostal: '',
          ville: '',
        },
        expertise: {
          caractere: '',
          type: TypeExpertise.EAD,
          typeEAD: null,
        },
        cabinetEtAgence: {
          cabinet: '',
          agence: '',
          expert: '',
        },
      },
      onSubmit: onSubmit,
      validationSchema: newMissionSchema,
    },
  )

  const {
    currentData: companyList = [],
    isFetching: isFetchingCompanyList,
  } = useGetCompanyListQuery()
  const {
    currentData: missionTypeList = [],
    isFetching: isFetchingMissionTypeList,
  } = useGetMissionTypeQuery()
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterNatureListQuery()
  const {
    currentData: expertiseCaractereList = [],
    isFetching: isFetchingExpertiseCaractereList,
  } = useGetExpertiseCaractereListQuery()
  const {
    currentData: expertiseNatureList = [],
    isFetching: isFetchingExpertiseNatureList,
  } = useGetExpertiseNatureListQuery()
  const {
    currentData: cabinetList = [],
    isFetching: isFetchingCabinetList,
  } = useGetCabinetListQuery()
  const {
    currentData: agencyList = [],
    isFetching: isFetchingAgencyList,
  } = useGetAgencyListQuery({ cabinet: formikForm.values.cabinetEtAgence.cabinet })
  const {
    currentData: expertList = [],
    isFetching: isFetchingExpertList,
  } = useGetExpertListQuery(
    { cabinet: formikForm.values.cabinetEtAgence.cabinet, agence: formikForm.values.cabinetEtAgence.agence },
    { skip: !formikForm.values.cabinetEtAgence.cabinet },
  )

  useEffect(() => {
    formikForm.setFieldValue('cabinetEtAgence.agence', '')
  }, [ isFetchingAgencyList ])

  useEffect(() => {
    formikForm.setFieldValue('cabinetEtAgence.expert', '')
  }, [ isFetchingExpertList ])

  const onNewStakeholderClick = () => {
    formikForm.setFieldValue('tiers', [ ...formikForm.values.tiers, defaultPerson ])
  }

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      setFiles([ ...files, ...Object.values(e.target.files ?? {}) ])
    }
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number): void => {
    setAnchorMenu(event.currentTarget)
    setFileIndex(index)
  }

  const handleMenuClose = () => {
    setAnchorMenu(null)
  }

  const onDeleteFile = () => {
    const newFiles = [ ...files ]
    newFiles.splice(fileIndex, 1)
    setFiles(newFiles)
    handleMenuClose()
  }

  const typeExpertiseOptions = enumToSegmentedButtonOptions(TypeExpertise)

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Nouvelle mission
        <Button
          variant="contained"
          type="submit"
        >
          Envoyer
        </Button>
      </LargeTitle>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Identification de la mission
            {(isFetchingCompanyList || isFetchingMissionTypeList) && <CircularProgress size={24} />}
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Compagnie
              </FormBoldTitle>
              <Field
                component={Select}
                name="compagnie"
                displayEmpty
                disabled={isFetchingCompanyList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  companyList.map((company, index) => (
                    <MenuItem
                      value={company.code}
                      key={`${company.code}-${index}`}
                    >
                      {company.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                N° sinistre
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="N° sinistre"
                name="sinistre.numero"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                N° police
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="N° police"
                name="numeroPolice"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Type de mission
              </FormBoldTitle>
              <Field
                component={Select}
                name="typeMission"
                displayEmpty
                disabled={isFetchingMissionTypeList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  missionTypeList.map((company, index) => (
                    <MenuItem
                      value={company.code}
                      key={`${company.code}-${index}`}
                    >
                      {company.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Identification de l'assuré
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Nom
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Nom"
                name="assure.nom"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Prénom
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Prénom"
                name="assure.prenom"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Tel mobile
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Tel mobile"
                name="assure.telephoneMobile"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Tel fixe
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Tel fixe"
                name="assure.telephoneFixe"
                size="small"
              />
            </FieldContainer>
          </InfoContainer>
          <FieldContainer>
            <FormBoldTitle smaller>
              Adresse
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Adresse"
              name="assure.adresse1"
              size="small"
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="assure.adresse2"
              size="small"
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="assure.adresse3"
              size="small"
            />
          </FieldContainer>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Code postal
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Code postal"
                name="assure.codePostal"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Ville
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Ville"
                name="assure.ville"
                size="small"
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Identification du sinistre
            {isFetchingDisasterNatureList && <CircularProgress size={24} />}
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Nature sinistre
              </FormBoldTitle>
              <Field
                component={Select}
                name="sinistre.nature"
                displayEmpty
                disabled={isFetchingDisasterNatureList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  disasterNatureList.map((value, index) => (
                    <MenuItem
                      value={value.code}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Date
              </FormBoldTitle>
              <DatePicker name="sinistre.date" />
            </FieldContainer>
          </InfoContainer>
          <FieldContainer>
            <FormBoldTitle smaller>
              Adresse
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Adresse"
              name="sinistre.adresse1"
              size="small"
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="sinistre.adresse2"
              size="small"
            />
          </FieldContainer>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Code postal
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Code postal"
                name="sinistre.codePostal"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Ville
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Ville"
                name="sinistre.ville"
                size="small"
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Causes et circonstances
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Description
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Description"
                name="causeEtCirconstances"
                rows={3}
                size="small"
                multiline
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Description des dommages
          </BoldTitle>
          <FormBoldTitle smaller>
            Montant
          </FormBoldTitle>
          <PriceFieldContainer
            name="dommage.montant"
            size="small"
            value={formikForm.values.dommage.montant}
            onChange={(e) => formikForm.setFieldValue('dommage.montant', handleNumberVerification(e.target.value, 2))}
          />
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Description
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Description"
                name="dommage.commentaire"
                rows={3}
                size="small"
                multiline
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Tiers impliqués
            <Button
              variant="outlined"
              onClick={onNewStakeholderClick}
            >
              Ajouter un tiers impliqué
            </Button>
          </BoldTitle>
          {
            formikForm.values.tiers.map((tiers, index) => (
              <CardContainer key={`${tiers.nom}-${index}`}>
                <CardContentContainer>
                  <FormBoldTitle>
                    {`TIERS ${index + 1}`}
                  </FormBoldTitle>
                  <InfoContainer>
                    <FieldContainer>
                      <FormBoldTitle
                        smaller
                        required
                      >
                        Nom
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Nom"
                        name={`tiers[${index}].nom`}
                        size="small"
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <FormBoldTitle smaller>
                        Prénom
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Prénom"
                        name={`tiers[${index}].prenom`}
                        size="small"
                      />
                    </FieldContainer>
                  </InfoContainer>
                  <FieldContainer>
                    <FormBoldTitle smaller>
                      Adresse
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Adresse"
                      name={`tiers[${index}].adresse1`}
                      size="small"
                    />
                    <AddressSeparator />
                    <Field
                      component={TextField}
                      placeholder="Adresse"
                      name={`tiers[${index}].adresse2`}
                      size="small"
                    />
                    <AddressSeparator />
                    <Field
                      component={TextField}
                      placeholder="Adresse"
                      name={`tiers[${index}].adresse3`}
                      size="small"
                    />
                  </FieldContainer>
                  <InfoContainer>
                    <FieldContainer>
                      <FormBoldTitle
                        smaller
                        required
                      >
                        Code postal
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Code postal"
                        name={`tiers[${index}].codePostal`}
                        size="small"
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <FormBoldTitle
                        smaller
                        required
                      >
                        Ville
                      </FormBoldTitle>
                      <Field
                        component={TextField}
                        placeholder="Ville"
                        name={`tiers[${index}].ville`}
                        size="small"
                      />
                    </FieldContainer>
                  </InfoContainer>
                </CardContentContainer>
              </CardContainer>
            ))
          }
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Demande d'expertise
            {(isFetchingExpertiseCaractereList || isFetchingExpertiseNatureList) && <CircularProgress size={24} />}
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Caractère
              </FormBoldTitle>
              <Field
                component={Select}
                name="expertise.caractere"
                displayEmpty
                disabled={isFetchingExpertiseCaractereList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  expertiseCaractereList.map((value, index) => (
                    <MenuItem
                      value={value.code}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Type
              </FormBoldTitle>
              <SegmentedButtons
                options={typeExpertiseOptions}
                selectedOption={formikForm.values.expertise.type}
                setSelectedOption={(newVal) => formikForm.setFieldValue('expertise.type', newVal)}
                smaller
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Type EAD
              </FormBoldTitle>
              <Field
                component={Select}
                name="expertise.typeEAD"
                value={formikForm.values.expertise.typeEAD || ''}
                renderValue={verifySelectFieldValue(formikForm.values.expertise.typeEAD?.toString())}
                displayEmpty
                disabled={isFetchingExpertiseNatureList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  expertiseNatureList.filter((v) => v.famille === 'EAD').map((value, index) => (
                    <MenuItem
                      value={value.code}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Choix du cabinet
            {(isFetchingAgencyList || isFetchingCabinetList || isFetchingExpertList) && <CircularProgress size={24} />}
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Cabinet
              </FormBoldTitle>
              <Field
                component={Select}
                name="cabinetEtAgence.cabinet"
                displayEmpty
                disabled={isFetchingCabinetList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  cabinetList.map((value, index) => (
                    <MenuItem
                      value={value.code}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Agence
              </FormBoldTitle>
              <Field
                component={Select}
                name="cabinetEtAgence.agence"
                displayEmpty
                disabled={isFetchingAgencyList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  agencyList.map((value, index) => (
                    <MenuItem
                      value={value.code}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Expert
              </FormBoldTitle>
              <Field
                component={Select}
                name="cabinetEtAgence.expert"
                displayEmpty
                disabled={isFetchingExpertList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  expertList.map((value, index) => (
                    <MenuItem
                      value={value.code}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Pièce jointe
            <AttachmentButton onChange={handleOnFileChange}>
              <Button
                variant="outlined"
                component="span"
              >
                Ajouter une pièce jointe
              </Button>
            </AttachmentButton>
          </BoldTitle>
          <FileInfoContainer>
            {
              files.map((file, index) => (
                <FileContainer key={`${file.name}-${index}`}>
                  <FileNameContainer>
                    <TaskRounded color="primary" />
                    {file.name}
                  </FileNameContainer>
                  <EditButton
                    variant="text"
                    onClick={(e) => handleMenuClick(e, index)}
                  >
                    <MoreVertRounded color="primary" />
                  </EditButton>
                  <Menu
                    anchorEl={anchorMenu}
                    open={menuOpen}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={onDeleteFile}>
                      Supprimer
                    </MenuItem>
                  </Menu>
                </FileContainer>
              ))
            }
          </FileInfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Message privé au prestataire
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Message
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Message"
                name="message"
                rows={3}
                size="small"
                multiline
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
    </Form>
  )
}

export default NewMissionPage
