/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostChangePasswordMutation,
  useGetIntituleListQuery,
  useGetProfileInfosQuery,
  usePostUpdateProfileInfosMutation,
  usePostUpdateProfilePictureMutation,
} from 'store/api'
import { verifySelectFieldValue } from 'helpers/verifySelectFieldValue'
import { isValidString } from 'helpers/isValidString'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Button,
  MenuItem,
} from '@mui/material'
import { Field } from 'formik'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import RouteTitle from 'router/RouteTitle'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AvatarUploadRenderer from 'components/MediaRenderer/AvatarUploadRenderer'
import LongButton from 'components/LongButton/LongButton'
import FormikPasswordInput from 'components/AuthComponents/FormikPasswordInput'
// import CheckableButton from 'components/CheckableButton/CheckableButton'
import MyProfileAvatarUploader from './MyProfileComponents/MyProfileAvatarUploader'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { ApiResponse } from 'helpers/fetchHelpers'
import type {
  CodeLabel,
  MonCompteEpass,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface NotificationSettings {
  email: boolean;
  application: boolean;
}

interface Notifications {
  notifications: {
    priseDeContact: NotificationSettings;
    priseDeRdv: NotificationSettings;
    preRapport: NotificationSettings;
    rapport: NotificationSettings;
  };
}

interface Password {
  newPassword: string;
  newPasswordRepeat: string;
  oldPassword: string;
}

type ProfileAndNotification = MonCompteEpass & Notifications & Password

const myProfileSchema = Yup.object().shape<Shape<ProfileAndNotification>>({
  intitule: Yup.mixed<CodeLabel>(),
  nom: Yup.string().required('Le nom est obligatoire'),
  prenom: Yup.string(),
  email: Yup.string().email("L'email est invalide").required("L'email est obligatoire"),
  identifiant: Yup.string().required("L'identifiant est obligatoire"),
  notifications: Yup.object({
    priseDeContact: Yup.object({
      application: Yup.boolean(),
      email: Yup.boolean(),
    }),
    priseDeRdv: Yup.object({
      application: Yup.boolean(),
      email: Yup.boolean(),
    }),
    preRapport: Yup.object({
      application: Yup.boolean(),
      email: Yup.boolean(),
    }),
    rapport: Yup.object({
      application: Yup.boolean(),
      email: Yup.boolean(),
    }),
  }),
  newPassword: Yup.string(),
  newPasswordRepeat: Yup.string().when('newPassword', {
    is: (newPassword: string) => isValidString(newPassword),
    then: (schema) => schema.oneOf([ Yup.ref('newPassword') ], 'Les mots de passe ne correspondent pas').required('Les mots de passe ne correspondent pas'),
  }),
  oldPassword: Yup.string().when('newPassword', {
    is: (newPassword: string) => isValidString(newPassword),
    then: (schema) => schema.required('Votre ancien mot de passe est requis pour sa modification'),
  }),
}).required()

type MyProfileForm = FormikContextType<ProfileAndNotification>

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin-top: 20px;
`

const BlueTitle = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.15rem;
`

const MyInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 80px 0.75fr 1fr 1fr 1fr;
  gap: 10px;

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`

const LoginInfoContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`

const AvatarContainer = styled.div`
  align-self: end;

  @media ${(props) => props.theme.media.mobile.main} {
    margin-top: 10px;
    margin-bottom: -10px;
    align-self: center;
  }
`

const EmailContainer = styled.div`
  min-width: 250px;
`

const ButtonContainer = styled.div`
  align-self: end;

  @media ${(props) => props.theme.media.mobile.main} {
    align-self: auto;
  }

  button {
    height: 40px;

    @media ${(props) => props.theme.media.mobile.main} {
      margin-top: 10px;
      width: 100%;
    }
  }
`

// const NotificationOuterContainer = styled.div`
//   margin-top: 10px;

//   @media ${(props) => props.theme.media.desktop} {
//     display: grid;
//     grid-template-columns: repeat(2, 46%);
//     gap: 8%;
//   }
// `

// const NotificationContainer = styled.div`
//   display: flex;
//   width: 100%;
//   margin-bottom: 10px;
//   gap: 10px;
//   align-items: center;

//   div {
//     display: flex;
//     gap: 10px;
//     align-self: start;
//   }

//   @media ${(props) => props.theme.media.mobile.main} {
//     flex-direction: column;
//     gap: 0px;
//   }
// `

// const NotificationTitle = styled.div`
//   font-size: .9rem;
//   font-weight: bold;
//   width: 100%;

//   @media ${(props) => props.theme.media.tablet} {
//     width: 130px;
//   }

//   @media ${(props) => props.theme.media.mobile.main} {
//     margin-bottom: 5px;
//   }
// `

// const NotificationCheckableButton = styled(CheckableButton)`
//   height: 40px;
//   max-width: 250px;
//   min-width: 200px;
//   width: 100%;

//   @media ${(props) => props.theme.media.mobile.main} {
//     width: 100%;
//     max-width: 100%;
//     min-width: 100%;
//     margin-bottom: 5px;
//   }
// `

/* Component declaration ---------------------------------------------------- */
interface MyProfilePageProps {}

const MyProfilePage: React.FC<MyProfilePageProps> = () => {
  const [ imageUrl, setImageUrl ] = useState<string>('')
  const [ imageFile, setImageFile ] = useState<File | undefined>()
  const [ isChangePasswordActive, setIsChangePasswordActive ] = useState<boolean>(false)

  const {
    currentData: profileInfos,
    isFetching: isFetchingProfileInfos,
  } = useGetProfileInfosQuery()
  const [
    submitUpdateProfilePicture,
  ] = usePostUpdateProfilePictureMutation()
  const [
    submitUpdateProfileInfos,
  ] = usePostUpdateProfileInfosMutation()
  const [
    submitUpdatePassword,
  ] = usePostChangePasswordMutation()
  const {
    currentData: intituleList = [],
    isFetching: isFetchingIntituleList,
  } = useGetIntituleListQuery()

  const onSubmit = (values: ProfileAndNotification, { setSubmitting }: FormikHelpers<ProfileAndNotification>): void => {
    const onFinish = (response: ApiResponse<MonCompteEpass>): void => {
      setSubmitting(false)
      if (!isApiError(response)) {
        toast.success(`Vos informations ont bien été modifiées.`)
      } else {
        toast.error(`Une erreur est survenue lors de la mofication de vos informations.`)
      }
    }

    const onFinishPassword = (response: ApiResponse<MonCompteEpass>): void => {
      setSubmitting(false)
      if (!isApiError(response)) {
        toast.success(`Votre mot de passe à bien été modifié.`)
      } else {
        toast.error(`Une erreur est survenue lors de la mofication de votre mot de passe. Veuillez verifier votre ancien mot de passe.`)
      }
    }

    submitUpdateProfileInfos({
      ...values,
      intitule: values.intitule?.code,
    }).then(onFinish).catch(console.error)

    if (isValidString(values.newPassword)) {
      submitUpdatePassword({
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
      }).then(onFinishPassword).catch(console.error)
    }
  }

  const formikForm: MyProfileForm = useForm<ProfileAndNotification>(
    {
      initialValues: {
        intitule: { code: '', libelle: '' },
        nom: '',
        prenom: '',
        email: '',
        identifiant: '',
        urlPhotoDeProfil: '',
        notifications: {
          priseDeContact: {
            application: true,
            email: true,
          },
          priseDeRdv: {
            application: true,
            email: false,
          },
          preRapport: {
            application: false,
            email: true,
          },
          rapport: {
            application: false,
            email: true,
          },
        },
        newPassword: '',
        newPasswordRepeat: '',
        oldPassword: '',
      },
      validationSchema: myProfileSchema,
      onSubmit: onSubmit,
    },
  )

  useEffect(() => {
    if (!isFetchingProfileInfos) {
      formikForm.setValues({
        ...formikForm.values,
        ...profileInfos,
      })
    }
  }, [ isFetchingProfileInfos ])

  useEffect(() => {
    if (imageFile) {
      submitUpdateProfilePicture({ Fichier: imageFile }).catch(console.error)
    }
  }, [ imageFile ])

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      setImageUrl(URL.createObjectURL(e.target.files[0]))
    }
  }

  return (
    <Form form={formikForm}>
      <RouteTitle title="Mon compte" />
      <LargeTitle>
        Mon compte
        <LongButton
          variant="contained"
          type="submit"
          disabled={formikForm.isSubmitting}
        >
          Valider les modifications
        </LongButton>
      </LargeTitle>
      <CardContainer>
        <CardContent>
          <BlueTitle>
            Mes informations
          </BlueTitle>
          <MyInfoContainer>
            <AvatarContainer>
              <AvatarUploadRenderer
                width="70px"
                height="70px"
                file={imageFile}
                isValid={isValidString(formikForm.values.urlPhotoDeProfil)}
                onClick={handleOnFileChange}
              />
            </AvatarContainer>
            <div>
              <FormBoldTitle smaller>
                Intitulé
              </FormBoldTitle>
              <Field
                component={Select}
                name="intitule.code"
                displayEmpty
                value={formikForm.values.intitule?.code || ''}
                renderValue={verifySelectFieldValue(formikForm.values.intitule?.code)}
                size="small"
                disabled={isFetchingIntituleList}
              >
                {
                  intituleList.map(({ code, libelle }) => (
                    <MenuItem
                      value={code}
                      key={code}
                    >
                      {libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </div>
            <div>
              <FormBoldTitle
                required
                smaller
              >
                Nom
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Nom"
                name="nom"
                size="small"
              />
            </div>
            <div>
              <FormBoldTitle smaller>
                Prénom
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Prénom"
                name="prenom"
                size="small"
              />
            </div>
            <EmailContainer>
              <FormBoldTitle
                required
                smaller
              >
                Email
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Email"
                name="email"
                size="small"
              />
            </EmailContainer>
          </MyInfoContainer>
        </CardContent>
      </CardContainer>
      <CardContainer>
        <CardContent>
          <BlueTitle>
            Connexion
          </BlueTitle>
          <LoginInfoContainer>
            <div>
              <FormBoldTitle
                required
                smaller
              >
                Identifiant
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Identifiant"
                name="identifiant"
                size="small"
                autoComplete="username"
              />
            </div>
            {
              !isChangePasswordActive &&
                <ButtonContainer>
                  <Button
                    variant="contained"
                    onClick={() => setIsChangePasswordActive(true)}
                  >
                    Modifier le mot de passe
                  </Button>
                </ButtonContainer>
            }
          </LoginInfoContainer>
          {
            isChangePasswordActive &&
              <>
                <br />
                <BlueTitle>
                  Changer le mot de passe
                </BlueTitle>
                <LoginInfoContainer>
                  <div>
                    <FormBoldTitle smaller>
                      Ancien mot de passe
                    </FormBoldTitle>
                    <FormikPasswordInput
                      placeholder="Ancien mot de passe"
                      name="oldPassword"
                      size="small"
                      autoComplete="current-password"
                    />
                  </div>
                  <div>
                    <FormBoldTitle smaller>
                      Mot de passe
                    </FormBoldTitle>
                    <FormikPasswordInput
                      placeholder="Nouveau mot de passe"
                      name="newPassword"
                      size="small"
                      autoComplete="new-password"
                    />
                  </div>
                  <div>
                    <FormBoldTitle smaller>
                      Confirmer le mot de passe
                    </FormBoldTitle>
                    <FormikPasswordInput
                      placeholder="Confirmer le mot de passe"
                      name="newPasswordRepeat"
                      size="small"
                      autoComplete="new-password"
                    />
                  </div>
                </LoginInfoContainer>
              </>
          }
        </CardContent>
      </CardContainer>
      {/* <CardContainer>
        <CardContent>
          <BlueTitle>
            Notifications
          </BlueTitle>
          <NotificationOuterContainer>
            <div>
              <NotificationContainer>
                <NotificationTitle>
                  Prise de contact
                </NotificationTitle>
                <NotificationCheckableButton
                  checked={formikForm.values.notifications.priseDeContact.application ?? false}
                  onChange={(e, c): void => handleValue('notifications.priseDeContact.application', c)}
                  label="Sur l'application"
                />
                <NotificationCheckableButton
                  checked={formikForm.values.notifications.priseDeContact.email ?? false}
                  onChange={(e, c): void => handleValue('notifications.priseDeContact.email', c)}
                  label="Par email"
                />
              </NotificationContainer>
              <NotificationContainer>
                <NotificationTitle>
                  Prise de RDV
                </NotificationTitle>
                <NotificationCheckableButton
                  checked={formikForm.values.notifications.priseDeRdv.application ?? false}
                  onChange={(e, c): void => handleValue('notifications.priseDeRdv.application', c)}
                  label="Sur l'application"
                />
                <NotificationCheckableButton
                  checked={formikForm.values.notifications.priseDeRdv.email ?? false}
                  onChange={(e, c): void => handleValue('notifications.priseDeRdv.email', c)}
                  label="Par email"
                />
              </NotificationContainer>
            </div>
            <div>
              <NotificationContainer>
                <NotificationTitle>
                  Pré rapport
                </NotificationTitle>
                <NotificationCheckableButton
                  checked={formikForm.values.notifications.preRapport.application ?? false}
                  onChange={(e, c): void => handleValue('notifications.preRapport.application', c)}
                  label="Sur l'application"
                />
                <NotificationCheckableButton
                  checked={formikForm.values.notifications.preRapport.email ?? false}
                  onChange={(e, c): void => handleValue('notifications.preRapport.email', c)}
                  label="Par email"
                />
              </NotificationContainer>
              <NotificationContainer>
                <NotificationTitle>
                  Rapport
                </NotificationTitle>
                <NotificationCheckableButton
                  checked={formikForm.values.notifications.rapport.application ?? false}
                  onChange={(e, c): void => handleValue('notifications.rapport.application', c)}
                  label="Sur l'application"
                />
                <NotificationCheckableButton
                  checked={formikForm.values.notifications.rapport.email ?? false}
                  onChange={(e, c): void => handleValue('notifications.rapport.email', c)}
                  label="Par email"
                />
              </NotificationContainer>
            </div>
          </NotificationOuterContainer>
        </CardContent>
      </CardContainer> */}
      {
        imageUrl !== '' &&
          <MyProfileAvatarUploader
            handleClose={() => setImageUrl('')}
            image={imageUrl}
            setImageFile={setImageFile}
            open
          />
      }
    </Form>
  )
}

export default MyProfilePage
