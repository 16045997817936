/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'
// import { downloadFile } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { InsertDriveFileOutlined } from '@mui/icons-material'

/* Styled components -------------------------------------------------------- */
interface DownloadButtonProps {
  sub: string;
}

const DownloadButton = styled(Button)<DownloadButtonProps>`
  svg {
    margin-left: -5px;
    margin-right: 5px;
  }
  align-items: end;
  padding: 2px 0px;
  width: 120px;

  @media ${(props) => props.theme.media.mobile.main} {
    margin-left: ${(props) => props.sub === 'true' ? '30px' : '0px'};
  }
`

/* Component declaration ---------------------------------------------------- */
interface ExpertiseRdvDownloadButtonProps {
  url?: string;
  name: string;
  sub?: boolean;
}

const ExpertiseRdvDownloadButton: React.FC<ExpertiseRdvDownloadButtonProps> = ({
  url,
  // name,
  sub = false,
}) => {

  const onClick = () => {
    // downloadFile(`Convocation_${name}.pdf`, url)
    window.open(url || '', '_blank', 'noreferrer')
  }

  return (
    <DownloadButton
      variant="outlined"
      size="small"
      onClick={onClick}
      disabled={!isValidString(url)}
      sub={sub ? 'true' : 'false'}
    >
      <InsertDriveFileOutlined />
      Convocation
    </DownloadButton>
  )
}

export default ExpertiseRdvDownloadButton
