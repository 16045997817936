/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import DocumentsThumbnail from './DocumentsThumbnail'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'
import { TypePJ } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const GridContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  gap: 20px;
  align-items: stretch;
  justify-content: stretch;
  min-height: 200px;
`

/* Component declaration ---------------------------------------------------- */
interface DocumentsThumbnailViewProps {
  filteredDocumentList: PieceJointe[];
  isFetchingDocumentList: boolean;
  selected: PieceJointe[];
  setSelected: (value: PieceJointe[]) => void;
  groupByType: boolean;
}

const DocumentsThumbnailView: React.FC<DocumentsThumbnailViewProps> = ({
  filteredDocumentList,
  isFetchingDocumentList,
  selected,
  setSelected,
  groupByType,
}) => {
  const images = useMemo(() => filteredDocumentList.filter((document) => document.type === TypePJ.Image), [ filteredDocumentList ])
  const docs = useMemo(() => filteredDocumentList.filter((document) => document.type !== TypePJ.Image), [ filteredDocumentList ])

  return (
    <LoadingOverlay isLoading={isFetchingDocumentList}>
      {
        groupByType ?
          <div>
            {
              images.length > 0 && (
                <div>
                  <FormBoldTitle>
                    {`PHOTOS (${images.length})`}
                  </FormBoldTitle>
                  <GridContainer>
                    {
                      images.map((document, index) => (
                        <DocumentsThumbnail
                          key={`${document.fileName}-${index}`}
                          document={document}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      ))
                    }
                  </GridContainer>
                </div>
              )
            }
            {
              docs.length > 0 && (
                <div>
                  <FormBoldTitle>
                    {`DOCUMENTS (${docs.length})`}
                  </FormBoldTitle>
                  <GridContainer>
                    {
                      docs.map((document, index) => (
                        <DocumentsThumbnail
                          key={`${document.fileName}-${index}`}
                          document={document}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      ))
                    }
                  </GridContainer>
                </div>
              )
            }
          </div> :
          <GridContainer>
            {
              filteredDocumentList.map((document, index) => (
                <DocumentsThumbnail
                  key={`${document.fileName}-${index}`}
                  document={document}
                  selected={selected}
                  setSelected={setSelected}
                />
              ))
            }
          </GridContainer>
      }
    </LoadingOverlay>
  )
}

export default DocumentsThumbnailView
