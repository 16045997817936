/* Component imports -------------------------------------------------------- */
import NewMissionPage from 'pages/NewMissionPage/NewMissionPage'

/* Component declaration ---------------------------------------------------- */
interface MainTab {
  label: string;
  path: string;
  component: React.ComponentType<{props: object}>;
}

const defaultMainTabs: MainTab[] = [
  {
    label: 'Nouvelle mission',
    path: 'nouvelle-mission',
    component: NewMissionPage,
  },
]

export default defaultMainTabs
