/* Type imports ------------------------------------------------------------- */
import type { Adresse } from 'API/__generated__/Api'

/* isValidString helper function -------------------------- */
export const getAddress = (address?: Adresse): string => {
  return [
    address?.adresse1,
    address?.adresse2,
    address?.adresse3,
    address?.codePostal,
    address?.ville,
  ].join(' ')
}
