/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getAddress } from 'helpers/getAddress'
import {
  useIsDesktop,
  useIsMobile,
  useIsTabletPortrait,
} from 'helpers/hooks/useIsMedia'
import { useWindowSize } from 'helpers/hooks/useWindowSize'
import { isValidString } from 'helpers/isValidString'

/* Component imports -------------------------------------------------------- */
import { Collapse } from '@mui/material'
import DetailsLoaderContainer from 'pages/CasePages/DetailsPage/DetailsComponents/DetailsLoaderContainer'
import VignetteRendererWithFilename from 'components/MediaRenderer/VignetteRendererWithFilename'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'
import CaseLayoutSummaryWorkflow from './CaseLayoutSummaryWorkflow'

/* Type declaration ------------------------------------ */
import type {
  DatesCle,
  Dossier,
  InformationsGroupe,
  JalonSimplifie,
  PieceJointe,
} from 'API/__generated__/Api'
import {
  TypeAutreMedia,
  TypePersonne,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

interface BlueTitleProps {
  isDropdown?: boolean;
}

const BlueTitle = styled.div<BlueTitleProps>`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  margin: 3px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

const BoldSeparator = styled.div`
  width: 200% !important;
  margin: 15px -15px 5px -15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const GridContainer = styled.div`
  display: grid;
  gap: 20px;
  align-items: stretch;
  justify-content: stretch;

  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutSummaryProps {
  summary?: Dossier;
  groupInfos?: InformationsGroupe;
  keyDates?: DatesCle;
  documents: PieceJointe[];
  workflow: JalonSimplifie[];
  isFetchingWorkflow: boolean;
}

const CaseLayoutSummary: React.FC<CaseLayoutSummaryProps> = ({
  summary,
  groupInfos,
  keyDates,
  documents,
  workflow,
  isFetchingWorkflow,
}) => {
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const isTablet = useIsTabletPortrait()
  const size = useWindowSize()
  const [ expanded, setExpanded ] = useState<boolean>(false)

  const handleExpandClick = (): void => {
    setExpanded(!expanded)
  }

  const insuredPerson = useMemo(() => summary?.personnes?.find((person) => person.type === TypePersonne.Assure), [ summary ])

  const getInsuredPersonName = (): string => {
    return [ insuredPerson?.nom, insuredPerson?.prenom ].join(' ')
  }

  return (
    <SummaryContainer>
      <DetailsLoaderContainer isFetching={isFetchingWorkflow} />
      <CaseLayoutSummaryWorkflow
        workflow={workflow}
        windowResize={size}
      />
      <BoldSeparator />
      <BlueTitle>
        Sinistre
      </BlueTitle>
      <div>
        {'Nature : '}
        <b>
          {summary?.sinistre?.nature?.libelle}
        </b>
      </div>
      <div>
        {'Date : '}
        <b>
          {DateUtils.APIStrToLocalDateString(summary?.sinistre?.date)}
        </b>
      </div>
      <div>
        {'Lieu : '}
        <b>
          {getAddress(summary?.sinistre?.adresse)}
        </b>
      </div>
      <div>
        {'Réf sinistre : '}
        <b>
          {summary?.sinistre?.reference}
        </b>
      </div>
      <BoldSeparator />
      <BlueTitle>
        Assuré
      </BlueTitle>
      <b>
        {getInsuredPersonName()}
      </b>
      <div>
        {'Adresse : '}
        <b>
          {getAddress(insuredPerson?.adresse)}
        </b>
      </div>
      <div>
        {'Téléphone : '}
        <b>
          <a
            href={`tel:${insuredPerson?.tel}`}
            target="_self"
          >
            {insuredPerson?.tel}
          </a>
        </b>
      </div>
      <div>
        {'N° contrat : '}
        <b>
          {insuredPerson?.police}
        </b>
      </div>
      <BoldSeparator />
      <BlueTitle>
        Votre Expert
      </BlueTitle>
      <div>
        {'Agence : '}
        <b>
          {summary?.agence?.nom}
        </b>
      </div>
      <div>
        {'Expert : '}
        <b>
          {summary?.mission.expert?.nom}
          {' '}
          {summary?.mission.expert?.prenom}
        </b>
      </div>
      <div>
        {'Tel expert : '}
        <b>
          <a
            href={`tel:${summary?.mission.expert?.telephones.autres.find((tel) => tel.type === TypeAutreMedia.TelPortable)?.valeur}`}
            target="_self"
          >
            {summary?.mission.expert?.telephones.autres.find((tel) => tel.type === TypeAutreMedia.TelPortable)?.valeur}
          </a>
        </b>
      </div>
      <div>
        {'Assistante : '}
        <b>
          {summary?.mission.assistante?.nom}
          {' '}
          {summary?.mission.assistante?.prenom}
        </b>
      </div>
      <div>
        {'Réf : '}
        <b>
          {summary?.mission.reference}
        </b>
      </div>
      <div>
        {`Tel : `}
        <b>
          <a
            href={`tel:${groupInfos?.telephoneCRC}`}
            target="_self"
          >
            {groupInfos?.telephoneCRC}
          </a>
        </b>
      </div>
      <div>
        {'Mail : '}
        <b>
          <a
            href={`mailto:${groupInfos?.mailCRC}`}
            target="_self"
          >
            {groupInfos?.mailCRC}
          </a>
        </b>
      </div>
      {
        isValidString(groupInfos?.urlPlaquette) &&
          <div>
            <b>
              <a
                href={groupInfos?.urlPlaquette || ''}
                target="_blank"
                rel="noreferrer"
              >
                À propos d'Union d'Experts
              </a>
            </b>
          </div>
      }
      <BoldSeparator />
      <BlueTitle>
        Dates Clés
      </BlueTitle>
      <div>
        {`Mission reçue le : `}
        <b>
          {DateUtils.APIStrToLocalDateString(summary?.mission.recueLe)}
        </b>
      </div>
      <div>
        {'Date du dernier RDV planifié : '}
        <b>
          {DateUtils.APIStrToLocalDateString(keyDates?.dernierRendezVous)}
        </b>
      </div>
      <div>
        {'Date du rapport : '}
        <b>
          {DateUtils.APIStrToLocalDateString(keyDates?.dernierRapport)}
        </b>
      </div>
      <BoldSeparator />
      <BlueTitle
        onClick={handleExpandClick}
        isDropdown={isDesktop && documents.length > 0}
      >
        {`RE, PV, LA, FA ${isDesktop ? (`(${documents.length} disponible${documents.length > 1 ? 's' : ''})`) : ''}`}
        {
          isDesktop && documents.length > 0 &&
            <DropdownArrow expanded={expanded.toString()} />
        }
      </BlueTitle>
      <Collapse
        in={isMobile || isTablet ? true : expanded}
        timeout="auto"
        unmountOnExit
      >
        <GridContainer>
          {
            documents.map((document, index) => (
              <VignetteRendererWithFilename
                key={`${document.fileName}-${index}`}
                document={document}
                onClick={() => window.open(document.url || '', '_blank', 'noreferrer')}
                width="min(100px, 100%)"
                height="120px"
              />
            ))
          }
        </GridContainer>
      </Collapse>
    </SummaryContainer>
  )
}

export default CaseLayoutSummary
