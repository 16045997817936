/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getCaseStatusColor } from 'helpers/caseStatusColor'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Link,
} from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import MyCasesDocuments from './MyCasesDocuments'

/* Type imports ------------------------------------------------------------- */
import type {
  MesDossierResultat,
  ResumeDocumentsDossier,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface MyCasesMobileCardProps {
  myCase: MesDossierResultat;
  documentsInfo?: ResumeDocumentsDossier;
  isFetchingDocumentsInfo: boolean;
  handleNavigate: () => void;
}

const MyCasesMobileCard: React.FC<MyCasesMobileCardProps> = ({
  myCase,
  documentsInfo,
  isFetchingDocumentsInfo,
  handleNavigate,
}) => {

  const getStyle = () => {
    if (!documentsInfo?.nombreMedia) {
      return ({ paddingLeft: 0, marginTop: 5 })
    }
    if (documentsInfo.nombreMedia >= 10) {
      return ({ paddingLeft: 10, marginTop: 10 })
    }
    return ({ paddingLeft: 10, marginTop: 10 })
  }

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          <Link onClick={handleNavigate}>
            {myCase.sinistre.referenceCompagnie}
          </Link>
          <ColoredSquareChip
            color={getCaseStatusColor(myCase.etat.code)}
            smaller
          >
            {myCase.etat.libelle}
          </ColoredSquareChip>
        </TitleContainer>
        {`Compagnie : ${myCase.compagnie?.libelle}`}
        <br />
        {`Assuré : ${myCase.assure.nom}`}
        <br />
        {`N° de contrat : ${myCase.assure.police}`}
        <br />
        {`Agence traitante : ${myCase.agenceTraitante}`}
        <br />
        {`Nature du sinistre : ${myCase.sinistre.nature?.libelle}`}
        <br />
        {`Date sinistre : ${DateUtils.APIStrToLocalDateString(myCase.sinistre.date)}`}
        <br />
        {`Date dépot rapport : ${DateUtils.APIStrToLocalDateString(myCase.dateDepotRapport)}`}
        <div style={getStyle()}>
          <MyCasesDocuments
            documentsInfo={documentsInfo}
            caseId={myCase.id}
            isFetching={isFetchingDocumentsInfo}
          />
        </div>
      </CardContentContainer>
    </CardContainer>
  )
}

export default MyCasesMobileCard
