/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useGetGroupInfosQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
interface GroupLogoProps {}

const GroupLogo: React.FC<GroupLogoProps> = () => {
  const {
    currentData: groupInfos,
    isFetching: isFetchingGroupInfos,
  } = useGetGroupInfosQuery()

  if (isFetchingGroupInfos) {
    return (
      <CircularProgress
        color="secondary"
        className="loader-logo"
      />
    )
  }

  return (
    <img
      src={groupInfos?.urlLogo}
      alt="Logo"
      className="loader-logo"
    />
  )
}

export default GroupLogo
