/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { isValidString } from 'helpers/isValidString'
import { getRandomInt } from 'helpers/getRandomInt'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Link,
} from '@mui/material'
import {
  CalendarMonthRounded,
  DescriptionOutlined,
  InsertDriveFileOutlined,
  PhoneRounded,
} from '@mui/icons-material'
import NotificationsViewedButton from './NotificationsViewedButton'

/* Type imports ------------------------------------------------------------- */
import type { NotificationUtilisateur } from 'API/__generated__/Api'
import { TypeNotificationUtilisateur } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 35% calc(45% - 160px) 160px;
  font-size: .9rem;
  padding: 10px;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    align-items: start;
    display: flex;
    flex-direction: column;
    padding: 10px 15px 10px;
  }
`

interface InfoContainerProps {
  sideBorder?: boolean;
}

const InfoContainer = styled.div<InfoContainerProps>`
  padding: 0px ${(props) => props.sideBorder ? '20px' : 0};
  border-left: ${(props) => props.sideBorder ? '2px' : 0} solid ${(props) => props.theme.colors.grey};

  @media ${(props) => props.theme.media.mobile.landscape} {
    padding: 0px ${(props) => props.sideBorder ? '10px' : 0};
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    width: 100%;
    padding: ${(props) => props.sideBorder ? '10px' : 0} 0px;
    border-left: none;
    border-top: ${(props) => props.sideBorder ? '2px' : 0} solid ${(props) => props.theme.colors.grey};
  }
`

const TypeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface NotificationsTimelineCardProps {
  notification: NotificationUtilisateur;
  handleNavigate: () => void;

}

const NotificationsTimelineCard: React.FC<NotificationsTimelineCardProps> = ({
  notification,
  handleNavigate,
}) => {

  const getIcon = (code: TypeNotificationUtilisateur) => {
    switch (code) {
      case TypeNotificationUtilisateur.AccuseReception:
        return <PhoneRounded />
      case TypeNotificationUtilisateur.PriseDeRendezVous:
        return <CalendarMonthRounded />
      case TypeNotificationUtilisateur.PreRapport:
        return <InsertDriveFileOutlined />
      default:
        return <DescriptionOutlined />
    }
  }

  const getRandomDate = (date: string) => {
    const newDate = new Date(date)
    return new Date((newDate.setDate(newDate.getDate() + getRandomInt(31)))).toISOString()
  }

  return (
    <Card>
      <CardContentContainer>
        <InfoContainer>
          <TypeContainer>
            {getIcon(notification.type.code)}
            {notification.type.libelle}
          </TypeContainer>
        </InfoContainer>
        <InfoContainer sideBorder>
          <Link onClick={handleNavigate}>
            {notification.infoDossier.sinistre.referenceCompagnie}
          </Link>
          <div>
            {`${notification.infoDossier.assure.nom} (${notification.infoDossier.assure.police || ''})`}
          </div>
          <div>
            {`${notification.infoDossier.sinistre.nature?.libelle || ''} du ${DateUtils.APIStrToLocalDateString(notification.infoDossier.sinistre.date)}`}
          </div>
        </InfoContainer>
        {
          isValidString(notification.infoDossier.dateRendezVous) ?
            <InfoContainer sideBorder>
              {
                (notification.type.code === TypeNotificationUtilisateur.AccuseReception || notification.type.code === TypeNotificationUtilisateur.PriseDeRendezVous) ?
                  'Date de réception : ' :
                  'Date du RDV : '
              }
              <div>
                {DateUtils.APIStrToLocalDateString(getRandomDate(notification.infoDossier.dateRendezVous || ''))}
              </div>
            </InfoContainer> :
            <div />
        }
        <NotificationsViewedButton id={notification.id} />
      </CardContentContainer>
    </Card>
  )
}

export default NotificationsTimelineCard
