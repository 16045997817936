/* Type imports ------------------------------------------------------------- */
import type { ChipColor } from 'types/ChipColor'

/* getCaseStatusColor helper function ----------------------- */
export const getCaseStatusColor = (code: string): ChipColor => {
  switch (code) {
    case 'T':
      return 'red'
    case 'C':
      return 'blue'
    case 'R':
      return 'yellow'
    case 'P':
      return 'orange'
    default:
      return 'salmon'
  }
}
